
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 5.882352941176471% 1.7647058823529411% 0.5882352941176471% 2.9411764705882355% 1.1764705882352942% 1.7647058823529411% 4.117647058823529% 1.1764705882352942% 0.5882352941176471% 1.1764705882352942% 0.5882352941176471% 0.5882352941176471% 2.3529411764705883% 0.5882352941176471% 0.5882352941176471% 1.7647058823529411% 0.5882352941176471% 0.5882352941176471% 3.5294117647058822% 1.7647058823529411% 1.7647058823529411% 3.5294117647058822% 2.9411764705882355% 0.5882352941176471% 3.5294117647058822% 2.9411764705882355% 4.705882352941177% 2.9411764705882355% 11.764705882352942% 3.5294117647058822% 4.705882352941177% 2.9411764705882355% 5.294117647058823% 14.705882352941176%;
    grid-template-columns: 0.4333694474539545% 1.0834236186348862% 4.550379198266522% 0.6500541711809318% 3.791982665222102% 0.10834236186348863% 1.408450704225352% 0.6500541711809318% 0.10834236186348863% 1.9501625135427951% 1.9501625135427951% 1.0834236186348862% 5.958829902491875% 1.408450704225352% 0.7583965330444203% 0.9750812567713976% 0.6500541711809318% 0.7583965330444203% 2.38353196099675% 3.358613217768147% 3.466955579631636% 1.408450704225352% 0.7583965330444203% 4.333694474539545% 2.275189599133261% 2.38353196099675% 1.3001083423618636% 0.10834236186348863% 0.7583965330444203% 3.1419284940411703% 0.866738894907909% 2.7085590465872156% 4.8754062838569885% 1.408450704225352% 0.7583965330444203% 4.225352112676056% 2.058504875406284% 0.9750812567713976% 0.5417118093174431% 0.6500541711809318% 2.058504875406284% 1.5167930660888407% 0.3250270855904659% 0.3250270855904659% 4.550379198266522% 0.5417118093174431% 0.9750812567713976% 0.6500541711809318% 0.6500541711809318% 1.5167930660888407% 0.6500541711809318% 0.10834236186348863% 4.008667388949079% 0.10834236186348863% 3.358613217768147% 1.8418201516793067% 3.2502708559046587% 0.3250270855904659% 0.21668472372697725%;
}

.PtOtSubjectiveLow1 {
    grid-row-start: 1;
    grid-row-end: 31;
    grid-column-start: 2;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow2 {
    grid-row-start: 3;
    grid-row-end: 8;
    grid-column-start: 6;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow3 {
    grid-row-start: 3;
    grid-row-end: 8;
    grid-column-start: 14;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow4 {
    grid-row-start: 3;
    grid-row-end: 8;
    grid-column-start: 22;
    grid-column-end: 23;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow5 {
    grid-row-start: 3;
    grid-row-end: 7;
    grid-column-start: 3;
    grid-column-end: 5;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow6 {
    grid-row-start: 3;
    grid-row-end: 6;
    grid-column-start: 10;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow7 {
    grid-row-start: 3;
    grid-row-end: 8;
    grid-column-start: 27;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow8 {
    grid-row-start: 3;
    grid-row-end: 7;
    grid-column-start: 16;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow9 {
    grid-row-start: 3;
    grid-row-end: 8;
    grid-column-start: 34;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow10 {
    grid-row-start: 3;
    grid-row-end: 7;
    grid-column-start: 24;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow11 {
    grid-row-start: 3;
    grid-row-end: 8;
    grid-column-start: 42;
    grid-column-end: 43;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow12 {
    grid-row-start: 3;
    grid-row-end: 6;
    grid-column-start: 30;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow13 {
    grid-row-start: 3;
    grid-row-end: 8;
    grid-column-start: 50;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow14 {
    grid-row-start: 3;
    grid-row-end: 6;
    grid-column-start: 36;
    grid-column-end: 42;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow15 {
    grid-row-start: 3;
    grid-row-end: 7;
    grid-column-start: 45;
    grid-column-end: 47;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow16 {
    grid-row-start: 3;
    grid-row-end: 7;
    grid-column-start: 53;
    grid-column-end: 54;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow17 {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 6;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow18 {
    grid-row-start: 9;
    grid-row-end: 19;
    grid-column-start: 32;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow19 {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 14;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow20 {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 22;
    grid-column-end: 23;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow21 {
    grid-row-start: 11;
    grid-row-end: 16;
    grid-column-start: 10;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow22 {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 27;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow23 {
    grid-row-start: 11;
    grid-row-end: 15;
    grid-column-start: 16;
    grid-column-end: 17;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow24 {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 38;
    grid-column-end: 40;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow26 {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 46;
    grid-column-end: 48;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow27 {
    grid-row-start: 11;
    grid-row-end: 15;
    grid-column-start: 24;
    grid-column-end: 27;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow28 {
    grid-row-start: 11;
    grid-row-end: 15;
    grid-column-start: 30;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow29 {
    grid-row-start: 11;
    grid-row-end: 15;
    grid-column-start: 41;
    grid-column-end: 44;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow30 {
    grid-row-start: 11;
    grid-row-end: 15;
    grid-column-start: 49;
    grid-column-end: 52;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow31 {
    grid-row-start: 21;
    grid-row-end: 25;
    grid-column-start: 12;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow32 {
    grid-row-start: 22;
    grid-row-end: 25;
    grid-column-start: 6;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow33 {
    grid-row-start: 22;
    grid-row-end: 24;
    grid-column-start: 9;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow34 {
    grid-row-start: 27;
    grid-row-end: 30;
    grid-column-start: 7;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PtOtSubjectiveLow35 {
    grid-row-start: 28;
    grid-row-end: 29;
    grid-column-start: 3;
    grid-column-end: 4;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 32;
    grid-row-end: 35;
    grid-column-start: 55;
    grid-column-end: 58;
    width: 100%;
    height: 100%;
}
