
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 3.401360544217687% 3.741496598639456% 4.421768707482993% 0.3401360544217687% 2.380952380952381% 0.6802721088435374% 1.0204081632653061% 1.0204081632653061% 0.3401360544217687% 1.7006802721088434% 0.3401360544217687% 3.061224489795918% 2.7210884353741496% 0.6802721088435374% 1.0204081632653061% 1.0204081632653061% 0.3401360544217687% 1.7006802721088434% 3.401360544217687% 0.3401360544217687% 2.0408163265306123% 0.3401360544217687% 0.3401360544217687% 0.3401360544217687% 1.0204081632653061% 1.0204081632653061% 0.3401360544217687% 1.7006802721088434% 0.3401360544217687% 3.061224489795918% 0.3401360544217687% 2.0408163265306123% 0.3401360544217687% 0.3401360544217687% 0.3401360544217687% 1.0204081632653061% 0.6802721088435374% 0.3401360544217687% 0.3401360544217687% 1.7006802721088434% 0.3401360544217687% 3.061224489795918% 0.3401360544217687% 2.380952380952381% 0.6802721088435374% 1.3605442176870748% 0.6802721088435374% 0.3401360544217687% 1.7006802721088434% 0.3401360544217687% 3.401360544217687% 2.380952380952381% 1.0204081632653061% 1.0204081632653061% 0.6802721088435374% 0.6802721088435374% 1.7006802721088434% 3.401360544217687% 0.3401360544217687% 2.0408163265306123% 0.3401360544217687% 0.6802721088435374% 1.0204081632653061% 0.6802721088435374% 0.6802721088435374% 1.7006802721088434% 0.3401360544217687% 3.061224489795918% 0.3401360544217687% 2.380952380952381% 0.6802721088435374% 1.0204081632653061% 0.6802721088435374% 0.3401360544217687% 2.0408163265306123% 0.3401360544217687% 4.081632653061225%;
    grid-template-columns: 0.7957559681697612% 0.08841732979664015% 0.08841732979664015% 2.033598585322723% 1.0610079575596818% 0.08841732979664015% 0.1768346595932803% 1.4146772767462423% 0.7073386383731212% 0.3536693191865606% 0.26525198938992045% 1.6799292661361627% 0.7073386383731212% 0.5305039787798409% 1.4146772767462423% 0.618921308576481% 2.1220159151193636% 0.1768346595932803% 0.8841732979664014% 0.08841732979664015% 1.1494252873563218% 0.08841732979664015% 1.4146772767462423% 0.08841732979664015% 2.5641025641025643% 0.08841732979664015% 5.128205128205129% 0.5305039787798409% 0.08841732979664015% 0.7073386383731212% 0.08841732979664015% 0.4420866489832007% 25.375773651635722% 2.1220159151193636% 2.7409372236958442% 2.387267904509284% 0.1768346595932803% 0.5305039787798409% 1.3262599469496021% 4.155614500442087% 5.128205128205129% 0.5305039787798409% 0.8841732979664014% 0.3536693191865606% 25.375773651635722% 1.237842617152962%;
}

.OtPhysicalCerebral260 {
    grid-row-start: 1;
    grid-row-end: 200;
    grid-column-start: 1;
    grid-column-end: 200;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral21 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 16;
    width: 100%;
    height: 100%;
}
.CtGrooming_oral {
    grid-row-start: 5;
    grid-row-end: 11;
    grid-column-start: 21;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral25 {
    grid-row-start: 5;
    grid-row-end: 11;
    grid-column-start: 33;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral26 {
    grid-row-start: 6;
    grid-row-end: 9;
    grid-column-start: 4;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.CtFeedingEating {
    grid-row-start: 13;
    grid-row-end: 19;
    grid-column-start: 21;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral211 {
    grid-row-start: 13;
    grid-row-end: 19;
    grid-column-start: 33;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral212 {
    grid-row-start: 14;
    grid-row-end: 17;
    grid-column-start: 4;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.CtDressingUpper {
    grid-row-start: 21;
    grid-row-end: 29;
    grid-column-start: 20;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral216 {
    grid-row-start: 21;
    grid-row-end: 29;
    grid-column-start: 33;
    grid-column-end: 33;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral217 {
    grid-row-start: 22;
    grid-row-end: 27;
    grid-column-start: 4;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.CtDressingLower {
    grid-row-start: 21;
    grid-row-end: 29;
    grid-column-start: 40;
    grid-column-end: 44;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral221 {
    grid-row-start: 21;
    grid-row-end: 29;
    grid-column-start: 45;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral222 {
    grid-row-start: 23;
    grid-row-end: 27;
    grid-column-start: 10;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral223 {
    grid-row-start: 23;
    grid-row-end: 27;
    grid-column-start: 30;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.CtTransferBed {
    grid-row-start: 32;
    grid-row-end: 41;
    grid-column-start: 20;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral227 {
    grid-row-start: 32;
    grid-row-end: 41;
    grid-column-start: 33;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral228 {
    grid-row-start: 33;
    grid-row-end: 38;
    grid-column-start: 2;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.CtTransferToilet {
    grid-row-start: 32;
    grid-row-end: 41;
    grid-column-start: 40;
    grid-column-end: 44;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral232 {
    grid-row-start: 32;
    grid-row-end: 41;
    grid-column-start: 45;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral233 {
    grid-row-start: 34;
    grid-row-end: 38;
    grid-column-start: 10;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral234 {
    grid-row-start: 34;
    grid-row-end: 38;
    grid-column-start: 30;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.CtBedMobility {
    grid-row-start: 44;
    grid-row-end: 50;
    grid-column-start: 20;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral238 {
    grid-row-start: 44;
    grid-row-end: 50;
    grid-column-start: 33;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral239 {
    grid-row-start: 45;
    grid-row-end: 48;
    grid-column-start: 4;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
}
.CtBathing {
    grid-row-start: 52;
    grid-row-end: 58;
    grid-column-start: 20;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral243 {
    grid-row-start: 52;
    grid-row-end: 58;
    grid-column-start: 33;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral244 {
    grid-row-start: 53;
    grid-row-end: 57;
    grid-column-start: 4;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.CtToileting {
    grid-row-start: 60;
    grid-row-end: 67;
    grid-column-start: 20;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral248 {
    grid-row-start: 59;
    grid-row-end: 67;
    grid-column-start: 33;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral249 {
    grid-row-start: 61;
    grid-row-end: 66;
    grid-column-start: 3;
    grid-column-end: 6;
    width: 100%;
    height: 100%;
}
.CtOther {
    grid-row-start: 70;
    grid-row-end: 76;
    grid-column-start: 20;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral253 {
    grid-row-start: 70;
    grid-row-end: 76;
    grid-column-start: 33;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.OtPhysicalCerebral254 {
    grid-row-start: 71;
    grid-row-end: 74;
    grid-column-start: 3;
    grid-column-end: 5;
    width: 100%;
    height: 100%;
}
