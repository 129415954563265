.DropdownOptions .ui.checkbox input:checked ~ label:before {
    background: var(--primary-theme-color);
    border: none;
}

.DropdownOptions .ui.checkbox input:checked~.box:after, .DropdownOptions .ui.checkbox input:checked~label:after {
    color: white;
    transform: scale(0.9);
}

.DropdownOptions .ui.multiple.dropdown>.label {
    background: var(--primary-theme-chat-half-light);
    box-shadow: none;
}