
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 1.1111111111111112% 3.888888888888889% 0.5555555555555556% 0.5555555555555556% 1.9444444444444444% 0.5555555555555556% 1.1111111111111112% 2.5% 0.2777777777777778% 1.6666666666666667% 0.2777777777777778% 0.5555555555555556% 0.8333333333333334% 0.5555555555555556% 0.5555555555555556% 1.3888888888888888% 0.2777777777777778% 2.5% 2.2222222222222223% 0.2777777777777778% 1.1111111111111112% 0.8333333333333334% 0.2777777777777778% 1.3888888888888888% 2.7777777777777777% 1.9444444444444444% 0.2777777777777778% 0.5555555555555556% 0.8333333333333334% 0.5555555555555556% 0.2777777777777778% 0.2777777777777778% 1.3888888888888888% 2.7777777777777777% 0.2777777777777778% 1.6666666666666667% 0.2777777777777778% 0.2777777777777778% 0.2777777777777778% 0.8333333333333334% 0.5555555555555556% 0.5555555555555556% 1.3888888888888888% 0.2777777777777778% 2.5% 0.2777777777777778% 1.9444444444444444% 0.5555555555555556% 0.8333333333333334% 0.8333333333333334% 0.2777777777777778% 1.3888888888888888% 0.2777777777777778% 2.5% 0.2777777777777778% 1.9444444444444444% 0.5555555555555556% 1.1111111111111112% 0.5555555555555556% 0.2777777777777778% 1.3888888888888888% 0.2777777777777778% 2.7777777777777777% 1.9444444444444444% 0.2777777777777778% 0.5555555555555556% 0.8333333333333334% 0.5555555555555556% 0.5555555555555556% 1.3888888888888888% 2.7777777777777777% 0.2777777777777778% 1.9444444444444444% 0.5555555555555556% 0.8333333333333334% 0.5555555555555556% 1.9444444444444444% 0.2777777777777778% 6.666666666666667% 2.7777777777777777% 2.7777777777777777% 1.9444444444444444% 2.7777777777777777% 1.9444444444444444%;
    grid-template-columns: 0.4355400696864111% 0.17421602787456447% 1.480836236933798% 0.08710801393728224% 0.08710801393728224% 2.0034843205574915% 1.0452961672473868% 0.08710801393728224% 0.17421602787456447% 1.3937282229965158% 1.0452961672473868% 1.916376306620209% 0.6968641114982579% 0.5226480836236934% 1.480836236933798% 0.5226480836236934% 2.0905923344947737% 0.17421602787456447% 0.8710801393728222% 0.08710801393728224% 1.132404181184669% 0.08710801393728224% 0.17421602787456447% 0.7839721254355401% 0.4355400696864111% 0.08710801393728224% 0.8710801393728222% 1.7421602787456445% 5.052264808362369% 0.08710801393728224% 0.4355400696864111% 0.08710801393728224% 0.7839721254355401% 0.5226480836236934% 24.912891986062718% 2.0905923344947737% 2.700348432055749% 2.35191637630662% 0.17421602787456447% 0.6097560975609756% 1.2195121951219512% 4.094076655052265% 5.052264808362369% 0.5226480836236934% 0.8710801393728222% 0.34843205574912894% 17.50871080139373% 3.3101045296167246% 1.829268292682927% 2.35191637630662% 0.7839721254355401% 0.17421602787456447% 0.4355400696864111%;
}

.OtPhysicalDysfunctionActivityOfDailyLiving2 {
    grid-row-start: 3;
    grid-row-end: 81;
    grid-column-start: 2;
    grid-column-end: 53;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving3 {
    grid-row-start: 6;
    grid-row-end: 9;
    grid-column-start: 20;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving4 {
    grid-row-start: 5;
    grid-row-end: 8;
    grid-column-start: 4;
    grid-column-end: 18;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving5 {
    grid-row-start: 6;
    grid-row-end: 7;
    grid-column-start: 25;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.Ct2Grooming_oral {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 21;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving8 {
    grid-row-start: 14;
    grid-row-end: 15;
    grid-column-start: 31;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving9 {
    grid-row-start: 10;
    grid-row-end: 18;
    grid-column-start: 35;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving10 {
    grid-row-start: 12;
    grid-row-end: 17;
    grid-column-start: 4;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.Ct2FeedingEating {
    grid-row-start: 20;
    grid-row-end: 26;
    grid-column-start: 21;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving13 {
    grid-row-start: 22;
    grid-row-end: 23;
    grid-column-start: 31;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving14 {
    grid-row-start: 20;
    grid-row-end: 26;
    grid-column-start: 35;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving15 {
    grid-row-start: 21;
    grid-row-end: 24;
    grid-column-start: 4;
    grid-column-end: 12;
    width: 100%;
    height: 100%;
}
.Ct2DressingUpper {
    grid-row-start: 27;
    grid-row-end: 35;
    grid-column-start: 20;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving18 {
    grid-row-start: 30;
    grid-row-end: 31;
    grid-column-start: 30;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving19 {
    grid-row-start: 27;
    grid-row-end: 35;
    grid-column-start: 35;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
.Ct2DressingLower {
    grid-row-start: 27;
    grid-row-end: 35;
    grid-column-start: 41;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving23 {
    grid-row-start: 28;
    grid-row-end: 32;
    grid-column-start: 4;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving24 {
    grid-row-start: 27;
    grid-row-end: 35;
    grid-column-start: 47;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving25 {
    grid-row-start: 29;
    grid-row-end: 33;
    grid-column-start: 9;
    grid-column-end: 18;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving26 {
    grid-row-start: 29;
    grid-row-end: 32;
    grid-column-start: 25;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.Ct2TransferBed {
    grid-row-start: 37;
    grid-row-end: 45;
    grid-column-start: 20;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving29 {
    grid-row-start: 41;
    grid-row-end: 42;
    grid-column-start: 30;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving30 {
    grid-row-start: 36;
    grid-row-end: 45;
    grid-column-start: 35;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving31 {
    grid-row-start: 38;
    grid-row-end: 43;
    grid-column-start: 4;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.Ct2TransferToilet {
    grid-row-start: 37;
    grid-row-end: 45;
    grid-column-start: 41;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving35 {
    grid-row-start: 38;
    grid-row-end: 43;
    grid-column-start: 9;
    grid-column-end: 18;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving36 {
    grid-row-start: 37;
    grid-row-end: 45;
    grid-column-start: 47;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving37 {
    grid-row-start: 39;
    grid-row-end: 43;
    grid-column-start: 25;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.Ct2BedMobility {
    grid-row-start: 48;
    grid-row-end: 54;
    grid-column-start: 20;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving40 {
    grid-row-start: 50;
    grid-row-end: 51;
    grid-column-start: 30;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving41 {
    grid-row-start: 48;
    grid-row-end: 55;
    grid-column-start: 35;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving42 {
    grid-row-start: 49;
    grid-row-end: 52;
    grid-column-start: 4;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.Ct2Bathing {
    grid-row-start: 57;
    grid-row-end: 63;
    grid-column-start: 20;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving45 {
    grid-row-start: 59;
    grid-row-end: 60;
    grid-column-start: 30;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving46 {
    grid-row-start: 56;
    grid-row-end: 63;
    grid-column-start: 35;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving47 {
    grid-row-start: 58;
    grid-row-end: 61;
    grid-column-start: 4;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.Ct2Toileting {
    grid-row-start: 65;
    grid-row-end: 72;
    grid-column-start: 20;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving50 {
    grid-row-start: 68;
    grid-row-end: 69;
    grid-column-start: 30;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving51 {
    grid-row-start: 65;
    grid-row-end: 72;
    grid-column-start: 35;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving52 {
    grid-row-start: 66;
    grid-row-end: 71;
    grid-column-start: 4;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.Ct2Other {
    grid-row-start: 74;
    grid-row-end: 79;
    grid-column-start: 20;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving55 {
    grid-row-start: 76;
    grid-row-end: 77;
    grid-column-start: 30;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving56 {
    grid-row-start: 73;
    grid-row-end: 79;
    grid-column-start: 35;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionActivityOfDailyLiving57 {
    grid-row-start: 75;
    grid-row-end: 78;
    grid-column-start: 4;
    grid-column-end: 7;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 82;
    grid-row-end: 85;
    grid-column-start: 48;
    grid-column-end: 53;
    width: 100%;
    height: 100%;
}
