
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 2.525252525252525% 0.5050505050505051% 0.5050505050505051% 2.525252525252525% 0.5050505050505051% 1.5151515151515151% 0.5050505050505051% 1.5151515151515151% 1.5151515151515151% 0.5050505050505051% 6.0606060606060606% 1.0101010101010102% 1.0101010101010102% 0.5050505050505051% 0.5050505050505051% 2.525252525252525% 0.5050505050505051% 0.5050505050505051% 1.5151515151515151% 1.0101010101010102% 4.545454545454546% 1.5151515151515151% 1.5151515151515151% 1.0101010101010102% 1.0101010101010102% 1.0101010101010102% 0.5050505050505051% 2.0202020202020203% 0.5050505050505051% 6.565656565656566% 0.5050505050505051% 2.525252525252525% 1.0101010101010102% 1.5151515151515151% 1.5151515151515151% 2.0202020202020203% 0.5050505050505051% 9.090909090909092% 33.83838383838384% 0.0%;
    grid-template-columns: 0.3257328990228013% 1.6286644951140066% 0.10857763300760044% 3.474484256243214% 1.520086862106406% 0.3257328990228013% 0.760043431053203% 4.451682953311618% 0.10857763300760044% 1.1943539630836049% 0.10857763300760044% 0.10857763300760044% 0.6514657980456026% 0.10857763300760044% 0.8686210640608035% 0.9771986970684039% 0.8686210640608035% 1.0857763300760044% 1.4115092290988056% 1.4115092290988056% 0.760043431053203% 0.760043431053203% 0.3257328990228013% 0.5428881650380022% 0.21715526601520088% 2.9315960912052117% 2.7144408251900107% 1.1943539630836049% 0.21715526601520088% 0.760043431053203% 0.6514657980456026% 1.520086862106406% 0.6514657980456026% 1.3029315960912051% 0.760043431053203% 1.1943539630836049% 0.43431053203040176% 0.760043431053203% 1.4115092290988056% 1.737242128121607% 1.0857763300760044% 1.1943539630836049% 0.43431053203040176% 0.9771986970684039% 1.1943539630836049% 0.43431053203040176% 0.8686210640608035% 2.9315960912052117% 1.1943539630836049% 0.21715526601520088% 1.1943539630836049% 0.760043431053203% 2.6058631921824102% 2.1715526601520088% 4.5602605863192185% 0.5428881650380022% 0.6514657980456026% 6.514657980456026% 0.9771986970684039% 0.10857763300760044% 1.3029315960912051% 0.760043431053203% 1.4115092290988056% 22.909880564603693% 0.10857763300760044% 0.5428881650380022% 0.0%;
}

.PtPhysicalOrthoOpdLow1 {
    grid-row-start: 1;
    grid-row-end: 40;
    grid-column-start: 2;
    grid-column-end: 67;
    width: 100%;
    height: 100%;
}
.Ct6Sensory {
    grid-row-start: 4;
    grid-row-end: 11;
    grid-column-start: 10;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow5 {
    grid-row-start: 5;
    grid-row-end: 11;
    grid-column-start: 28;
    grid-column-end: 60;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow6 {
    grid-row-start: 6;
    grid-row-end: 10;
    grid-column-start: 3;
    grid-column-end: 5;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow8 {
    grid-row-start: 13;
    grid-row-end: 22;
    grid-column-start: 19;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow9 {
    grid-row-start: 15;
    grid-row-end: 21;
    grid-column-start: 10;
    grid-column-end: 12;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow10 {
    grid-row-start: 13;
    grid-row-end: 22;
    grid-column-start: 39;
    grid-column-end: 49;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow11 {
    grid-row-start: 15;
    grid-row-end: 18;
    grid-column-start: 4;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow12 {
    grid-row-start: 15;
    grid-row-end: 21;
    grid-column-start: 32;
    grid-column-end: 33;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow13 {
    grid-row-start: 13;
    grid-row-end: 22;
    grid-column-start: 55;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow14 {
    grid-row-start: 15;
    grid-row-end: 18;
    grid-column-start: 15;
    grid-column-end: 18;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow15 {
    grid-row-start: 15;
    grid-row-end: 21;
    grid-column-start: 50;
    grid-column-end: 52;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow16 {
    grid-row-start: 15;
    grid-row-end: 21;
    grid-column-start: 60;
    grid-column-end: 62;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow17 {
    grid-row-start: 15;
    grid-row-end: 19;
    grid-column-start: 33;
    grid-column-end: 38;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow18 {
    grid-row-start: 15;
    grid-row-end: 18;
    grid-column-start: 53;
    grid-column-end: 54;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow19 {
    grid-row-start: 15;
    grid-row-end: 18;
    grid-column-start: 63;
    grid-column-end: 64;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow20 {
    grid-row-start: 23;
    grid-row-end: 30;
    grid-column-start: 3;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.Ct3TransferDependent {
    grid-row-start: 23;
    grid-row-end: 30;
    grid-column-start: 37;
    grid-column-end: 49;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow24 {
    grid-row-start: 24;
    grid-row-end: 30;
    grid-column-start: 9;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow25 {
    grid-row-start: 24;
    grid-row-end: 30;
    grid-column-start: 20;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow26 {
    grid-row-start: 24;
    grid-row-end: 30;
    grid-column-start: 28;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow27 {
    grid-row-start: 24;
    grid-row-end: 28;
    grid-column-start: 14;
    grid-column-end: 17;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow28 {
    grid-row-start: 24;
    grid-row-end: 28;
    grid-column-start: 22;
    grid-column-end: 27;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow29 {
    grid-row-start: 24;
    grid-row-end: 28;
    grid-column-start: 31;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow30 {
    grid-row-start: 32;
    grid-row-end: 38;
    grid-column-start: 3;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow31 {
    grid-row-start: 33;
    grid-row-end: 38;
    grid-column-start: 10;
    grid-column-end: 40;
    width: 100%;
    height: 100%;
}
.Ct3GaitPatternAmbulation {
    grid-row-start: 33;
    grid-row-end: 38;
    grid-column-start: 44;
    grid-column-end: 58;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdLow35 {
    grid-row-start: 34;
    grid-row-end: 37;
    grid-column-start: 41;
    grid-column-end: 42;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 40;
    grid-row-end: 41;
    grid-column-start: 64;
    grid-column-end: 67;
    width: 100%;
    height: 100%;
}
