// ทำให้ font-size Dropdown มีขนาดเท่ากับ Input element อื่นๆ 
// เนื่องจากตัว CNMI เขียน CSS ให้ ขนาด Dropdown มีขนาด 16px 
// ซึ่งใน IHV3 นั้น มันไม่ ครอบคลุมทั้งหมด จึงจำเป็นต้อง นำ Override CNMI ออก 

#dashboard-telephar {
  .ui.form .inline.field > .selection.dropdown,
  .ui.form .inline.fields .field > .selection.dropdown {
    font-size: unset;
  }
}
