.medication-admin-ward-table {
  .medication-admin-custom-table {
    height: 700px;
  }

  .ui.button {
    &.button-tiny {
      font-size: 0.8em;
      padding-left: 1em !important;
      padding-right: 1em !important;
      &.icon {
        padding-left: 2em !important;
      }
    }
  }
}

.time-text-box-wrapper {
  display: flex;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  line-height: 1em;
  border: 1px solid #e0e0e0;
  height: 31px;
  .react-time-picker__wrapper {
    border: 0;
  }
  .react-time-picker__inputGroup {
    display: flex;
    align-items: center;
  }
}
