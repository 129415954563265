
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 4.184100418410042% 1.6736401673640167% 0.41841004184100417% 0.8368200836820083% 1.2552301255230125% 0.41841004184100417% 0.8368200836820083% 1.6736401673640167% 3.7656903765690375% 2.092050209205021% 0.41841004184100417% 0.8368200836820083% 0.8368200836820083% 0.41841004184100417% 0.8368200836820083% 2.092050209205021% 3.7656903765690375% 0.41841004184100417% 1.2552301255230125% 0.8368200836820083% 0.41841004184100417% 0.8368200836820083% 0.8368200836820083% 0.41841004184100417% 2.092050209205021% 3.7656903765690375% 1.6736401673640167% 0.41841004184100417% 0.8368200836820083% 1.2552301255230125% 0.8368200836820083% 0.41841004184100417% 1.6736401673640167% 3.7656903765690375% 1.2552301255230125% 0.41841004184100417% 0.41841004184100417% 2.510460251046025% 0.8368200836820083% 1.2552301255230125% 0.41841004184100417% 3.7656903765690375% 1.2552301255230125% 0.8368200836820083% 0.41841004184100417% 2.510460251046025% 0.41841004184100417% 1.2552301255230125% 0.41841004184100417% 10.460251046025105% 3.7656903765690375% 3.3472803347280333% 2.092050209205021% 3.7656903765690375% 10.460251046025105% 0.0%;
    grid-template-columns: 0.5458515283842795% 1.4192139737991267% 1.4192139737991267% 2.947598253275109% 0.7641921397379913% 0.8733624454148472% 0.2183406113537118% 4.475982532751091% 0.1091703056768559% 0.8733624454148472% 2.4017467248908297% 2.074235807860262% 0.32751091703056767% 1.3100436681222707% 5.021834061135372% 0.1091703056768559% 0.4366812227074236% 0.5458515283842795% 0.4366812227074236% 19.868995633187772% 44.65065502183406% 1.4192139737991267% 1.5283842794759825% 0.4366812227074236% 0.982532751091703% 0.7641921397379913% 1.4192139737991267% 1.8558951965065502% 0.5458515283842795% 0.2183406113537118% 0.0%;
}

.PoPhysicalAnkleFootOrthosisLow1 {
    grid-row-start: 1;
    grid-row-end: 52;
    grid-column-start: 2;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow2 {
    grid-row-start: 3;
    grid-row-end: 10;
    grid-column-start: 9;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow5 {
    grid-row-start: 3;
    grid-row-end: 10;
    grid-column-start: 20;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow6 {
    grid-row-start: 4;
    grid-row-end: 9;
    grid-column-start: 3;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow7 {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 9;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow10 {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 20;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow11 {
    grid-row-start: 12;
    grid-row-end: 17;
    grid-column-start: 3;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow12 {
    grid-row-start: 20;
    grid-row-end: 27;
    grid-column-start: 9;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow15 {
    grid-row-start: 19;
    grid-row-end: 27;
    grid-column-start: 20;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow16 {
    grid-row-start: 21;
    grid-row-end: 25;
    grid-column-start: 3;
    grid-column-end: 4;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow17 {
    grid-row-start: 28;
    grid-row-end: 35;
    grid-column-start: 9;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow20 {
    grid-row-start: 28;
    grid-row-end: 35;
    grid-column-start: 20;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow21 {
    grid-row-start: 29;
    grid-row-end: 33;
    grid-column-start: 3;
    grid-column-end: 5;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow22 {
    grid-row-start: 36;
    grid-row-end: 42;
    grid-column-start: 15;
    grid-column-end: 23;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow23 {
    grid-row-start: 38;
    grid-row-end: 41;
    grid-column-start: 10;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow24 {
    grid-row-start: 38;
    grid-row-end: 41;
    grid-column-start: 3;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow25 {
    grid-row-start: 38;
    grid-row-end: 42;
    grid-column-start: 24;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow26 {
    grid-row-start: 38;
    grid-row-end: 40;
    grid-column-start: 27;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow27 {
    grid-row-start: 44;
    grid-row-end: 50;
    grid-column-start: 15;
    grid-column-end: 23;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow28 {
    grid-row-start: 45;
    grid-row-end: 48;
    grid-column-start: 10;
    grid-column-end: 16;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow29 {
    grid-row-start: 45;
    grid-row-end: 50;
    grid-column-start: 24;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisLow30 {
    grid-row-start: 45;
    grid-row-end: 48;
    grid-column-start: 27;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 53;
    grid-row-end: 56;
    grid-column-start: 22;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
