
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 1.1764705882352942% 2.9411764705882355% 3.5294117647058822% 2.9411764705882355% 4.705882352941177% 2.3529411764705883% 5.882352941176471% 2.9411764705882355% 0.5882352941176471% 0.5882352941176471% 1.1764705882352942% 0.5882352941176471% 0.5882352941176471% 0.5882352941176471% 1.1764705882352942% 1.7647058823529411% 4.705882352941177% 3.5294117647058822% 4.705882352941177% 1.7647058823529411% 6.470588235294118% 1.7647058823529411% 1.1764705882352942% 0.5882352941176471% 2.9411764705882355% 0.5882352941176471% 0.5882352941176471% 1.7647058823529411% 1.1764705882352942% 4.117647058823529% 2.9411764705882355% 0.5882352941176471% 3.5294117647058822% 0.5882352941176471% 0.5882352941176471% 2.3529411764705883% 5.294117647058823% 1.1764705882352942% 1.1764705882352942% 0.5882352941176471% 0.5882352941176471% 2.9411764705882355% 1.1764705882352942% 1.7647058823529411% 0.5882352941176471% 1.7647058823529411% 2.9411764705882355% 0.0%;
    grid-template-columns: 0.8771929824561403% 1.0964912280701755% 0.10964912280701754% 3.508771929824561% 0.5482456140350878% 1.206140350877193% 0.8771929824561403% 0.5482456140350878% 3.9473684210526314% 0.21929824561403508% 0.8771929824561403% 0.21929824561403508% 0.10964912280701754% 0.7675438596491229% 0.9868421052631579% 1.7543859649122806% 0.9868421052631579% 4.385964912280702% 0.10964912280701754% 0.5482456140350878% 0.5482456140350878% 0.32894736842105265% 4.714912280701754% 1.644736842105263% 1.5350877192982457% 1.4254385964912282% 0.7675438596491229% 1.644736842105263% 2.0833333333333335% 0.5482456140350878% 0.6578947368421053% 1.9736842105263157% 7.12719298245614% 0.43859649122807015% 0.8771929824561403% 1.206140350877193% 1.0964912280701755% 1.4254385964912282% 0.10964912280701754% 0.5482456140350878% 0.10964912280701754% 0.6578947368421053% 0.21929824561403508% 1.7543859649122806% 1.3157894736842106% 0.6578947368421053% 0.10964912280701754% 1.8640350877192982% 1.644736842105263% 1.206140350877193% 1.9736842105263157% 5.482456140350878% 0.9868421052631579% 0.21929824561403508% 0.43859649122807015% 0.43859649122807015% 0.32894736842105265% 0.5482456140350878% 0.10964912280701754% 1.4254385964912282% 2.960526315789474% 0.7675438596491229% 12.171052631578947% 1.7543859649122806% 1.4254385964912282% 0.43859649122807015% 0.32894736842105265% 1.4254385964912282% 1.644736842105263% 0.10964912280701754% 0.5482456140350878% 0.43859649122807015% 0.10964912280701754% 0.0%;
}

.PtObjectiveOrthoTop1 {
    grid-row-start: 3;
    grid-row-end: 49;
    grid-column-start: 2;
    grid-column-end: 72;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop2 {
    grid-row-start: 5;
    grid-row-end: 8;
    grid-column-start: 11;
    grid-column-end: 67;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop4 {
    grid-row-start: 6;
    grid-row-end: 7;
    grid-column-start: 4;
    grid-column-end: 6;
    width: 100%;
    height: 100%;
}
.Ct9Consciousness {
    grid-row-start: 9;
    grid-row-end: 18;
    grid-column-start: 11;
    grid-column-end: 23;
    width: 100%;
    height: 100%;
}
.Ct12Communication {
    grid-row-start: 9;
    grid-row-end: 18;
    grid-column-start: 30;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop11 {
    grid-row-start: 10;
    grid-row-end: 15;
    grid-column-start: 4;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.Ct12CoOperation {
    grid-row-start: 9;
    grid-row-end: 18;
    grid-column-start: 50;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop15 {
    grid-row-start: 10;
    grid-row-end: 15;
    grid-column-start: 24;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop16 {
    grid-row-start: 10;
    grid-row-end: 17;
    grid-column-start: 44;
    grid-column-end: 50;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop17 {
    grid-row-start: 19;
    grid-row-end: 22;
    grid-column-start: 11;
    grid-column-end: 43;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop18 {
    grid-row-start: 20;
    grid-row-end: 21;
    grid-column-start: 4;
    grid-column-end: 6;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop19 {
    grid-row-start: 23;
    grid-row-end: 31;
    grid-column-start: 18;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop20 {
    grid-row-start: 23;
    grid-row-end: 31;
    grid-column-start: 31;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop21 {
    grid-row-start: 24;
    grid-row-end: 30;
    grid-column-start: 10;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop22 {
    grid-row-start: 24;
    grid-row-end: 28;
    grid-column-start: 4;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop23 {
    grid-row-start: 23;
    grid-row-end: 31;
    grid-column-start: 47;
    grid-column-end: 53;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop24 {
    grid-row-start: 24;
    grid-row-end: 30;
    grid-column-start: 26;
    grid-column-end: 27;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop25 {
    grid-row-start: 23;
    grid-row-end: 31;
    grid-column-start: 63;
    grid-column-end: 64;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop26 {
    grid-row-start: 24;
    grid-row-end: 27;
    grid-column-start: 15;
    grid-column-end: 17;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop27 {
    grid-row-start: 24;
    grid-row-end: 30;
    grid-column-start: 38;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop28 {
    grid-row-start: 24;
    grid-row-end: 28;
    grid-column-start: 28;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop29 {
    grid-row-start: 24;
    grid-row-end: 30;
    grid-column-start: 54;
    grid-column-end: 58;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop30 {
    grid-row-start: 24;
    grid-row-end: 28;
    grid-column-start: 41;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop31 {
    grid-row-start: 24;
    grid-row-end: 30;
    grid-column-start: 65;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop32 {
    grid-row-start: 24;
    grid-row-end: 27;
    grid-column-start: 60;
    grid-column-end: 62;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop33 {
    grid-row-start: 24;
    grid-row-end: 27;
    grid-column-start: 68;
    grid-column-end: 69;
    width: 100%;
    height: 100%;
}
.Ct4Sensory {
    grid-row-start: 32;
    grid-row-end: 38;
    grid-column-start: 11;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop35 {
    grid-row-start: 32;
    grid-row-end: 38;
    grid-column-start: 23;
    grid-column-end: 55;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop37 {
    grid-row-start: 34;
    grid-row-end: 36;
    grid-column-start: 3;
    grid-column-end: 5;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop38 {
    grid-row-start: 39;
    grid-row-end: 46;
    grid-column-start: 18;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop39 {
    grid-row-start: 41;
    grid-row-end: 46;
    grid-column-start: 10;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop40 {
    grid-row-start: 39;
    grid-row-end: 46;
    grid-column-start: 31;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop41 {
    grid-row-start: 41;
    grid-row-end: 44;
    grid-column-start: 4;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop42 {
    grid-row-start: 41;
    grid-row-end: 46;
    grid-column-start: 26;
    grid-column-end: 27;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop43 {
    grid-row-start: 39;
    grid-row-end: 46;
    grid-column-start: 48;
    grid-column-end: 53;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop44 {
    grid-row-start: 41;
    grid-row-end: 44;
    grid-column-start: 15;
    grid-column-end: 17;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop45 {
    grid-row-start: 41;
    grid-row-end: 46;
    grid-column-start: 38;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop46 {
    grid-row-start: 41;
    grid-row-end: 45;
    grid-column-start: 28;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop47 {
    grid-row-start: 41;
    grid-row-end: 46;
    grid-column-start: 54;
    grid-column-end: 58;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop48 {
    grid-row-start: 41;
    grid-row-end: 44;
    grid-column-start: 42;
    grid-column-end: 45;
    width: 100%;
    height: 100%;
}
.PtObjectiveOrthoTop49 {
    grid-row-start: 41;
    grid-row-end: 44;
    grid-column-start: 60;
    grid-column-end: 61;
    width: 100%;
    height: 100%;
}
