.medication-admin-custom-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  table {
    border-radius: none;
    thead {
      display: table;
      background: #2f3e53;
      border-radius: 0;
      padding-right: 10px;
    }
    tr {
      th {
        background: #2f3e53;
        color: white !important;
        padding: 5px !important;
        border: 1px solid !important;
        border-right: none !important;
        border-bottom: none !important;
        user-select: none;
      }
      td {
        border: none !important;
        border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
        padding: 3px;
        text-align: left;
        overflow: hidden;
        vertical-align: top;
        border: 1px solid #3a4756;
        border-top-style: none;
        box-sizing: border-box;
        height: 0;
      }
      th,
      td {
        text-overflow: ellipsis;
        overflow: hidden;
        transition: 0.3s ease;
        transition-property: width, min-width, padding, opacity;
      }
    }
    .not-found {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: rgba(255, 255, 255, 0.8);
      transition: all 0.3s ease;
      z-index: 1;
      pointer-events: none;
      padding: 20px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .ui.selectable.table tbody tr:hover,
  .ui.table tbody tr td.selectable:hover {
    background: #e3ebf7 !important;
  }
  .ui.selectable.table tbody .toggle-selected {
    background: #c3e8ff !important;
  }
  .ui.table td {
    padding: 0.3em 0.3em;
  }
  table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
  }
  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #eee;
  }
  table tbody.filters {
    flex: 0 0 auto;
    overflow-y: auto;
  }
  table tbody {
    /* body takes all the remaining available space */
    flex: 1 1 auto;
    overflow-y: auto;
    color: #2b2825;
    // min-width: 1350px;
  }
  table tbody tr {
    width: 100%;
    display: table;
  }
  // table thead,
  table tbody tr {
    width: 100%;
  }
  tbody.filters .ui.input > input {
    padding: 0.3em;
  }
  .toggle-icon {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .form-icon {
    padding-right: 5px;
    display: flex;
    margin: auto;
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.74);
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}

/* Styles go here */
