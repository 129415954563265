
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 3.811659192825112% 1.7937219730941705% 3.1390134529147984% 1.5695067264573992% 0.4484304932735426% 0.672645739910314% 0.4484304932735426% 0.2242152466367713% 0.2242152466367713% 1.345291479820628% 1.345291479820628% 1.5695067264573992% 0.4484304932735426% 0.672645739910314% 0.4484304932735426% 0.2242152466367713% 0.2242152466367713% 1.345291479820628% 5.829596412556054% 1.7937219730941705% 0.672645739910314% 2.4663677130044843% 1.5695067264573992% 0.4484304932735426% 0.8968609865470852% 0.2242152466367713% 0.2242152466367713% 0.2242152466367713% 1.345291479820628% 1.345291479820628% 1.5695067264573992% 0.4484304932735426% 0.8968609865470852% 0.2242152466367713% 0.2242152466367713% 0.2242152466367713% 1.345291479820628% 1.1210762331838564% 1.5695067264573992% 0.2242152466367713% 0.4484304932735426% 0.672645739910314% 0.2242152466367713% 0.2242152466367713% 0.2242152466367713% 1.345291479820628% 1.345291479820628% 1.5695067264573992% 0.4484304932735426% 0.8968609865470852% 0.2242152466367713% 0.2242152466367713% 0.2242152466367713% 1.345291479820628% 4.708520179372197% 0.672645739910314% 0.672645739910314% 0.2242152466367713% 1.7937219730941705% 0.4484304932735426% 0.8968609865470852% 0.2242152466367713% 3.587443946188341% 0.672645739910314% 0.672645739910314% 0.2242152466367713% 1.5695067264573992% 0.4484304932735426% 0.2242152466367713% 0.672645739910314% 0.4484304932735426% 1.345291479820628% 0.672645739910314% 0.672645739910314% 0.2242152466367713% 1.5695067264573992% 0.4484304932735426% 0.8968609865470852% 0.4484304932735426% 5.15695067264574% 0.672645739910314% 2.0179372197309418% 10.986547085201794% 2.4663677130044843%;
    grid-template-columns: 1.4991181657848325% 0.08818342151675485% 2.2045855379188715% 0.08818342151675485% 0.08818342151675485% 0.26455026455026454% 1.3227513227513228% 0.26455026455026454% 0.4409171075837742% 1.5873015873015872% 0.26455026455026454% 0.9700176366843033% 0.08818342151675485% 1.0582010582010581% 0.08818342151675485% 0.9700176366843033% 0.4409171075837742% 0.4409171075837742% 0.08818342151675485% 0.08818342151675485% 0.4409171075837742% 4.232804232804233% 1.6754850088183422% 0.7936507936507936% 0.08818342151675485% 0.4409171075837742% 0.6172839506172839% 0.3527336860670194% 20.01763668430335% 0.7936507936507936% 1.4109347442680775% 0.7936507936507936% 1.4109347442680775% 0.7054673721340388% 0.9700176366843033% 1.4991181657848325% 0.7936507936507936% 1.3227513227513228% 0.08818342151675485% 2.0282186948853616% 0.08818342151675485% 0.08818342151675485% 1.3227513227513228% 0.5291005291005291% 0.08818342151675485% 0.1763668430335097% 0.1763668430335097% 0.5291005291005291% 0.1763668430335097% 0.4409171075837742% 0.8818342151675485% 0.5291005291005291% 0.5291005291005291% 0.26455026455026454% 0.08818342151675485% 0.8818342151675485% 0.26455026455026454% 1.0582010582010581% 0.1763668430335097% 0.26455026455026454% 0.26455026455026454% 6.261022927689594% 0.4409171075837742% 0.08818342151675485% 0.5291005291005291% 0.4409171075837742% 14.814814814814815% 1.4109347442680775% 1.4991181657848325% 0.7054673721340388% 1.4991181657848325% 5.291005291005291% 0.8818342151675485% 1.4109347442680775% 0.7936507936507936% 1.3227513227513228% 0.1763668430335097% 0.5291005291005291% 0.26455026455026454%;
}

.OtPhysicalDysfunctionHandFunctionRight100 {
    grid-row-start: 1;
    grid-row-end: 115;
    grid-column-start: 1;
    grid-column-end: 221;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRightRight1 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.CtReach {
    grid-row-start: 4;
    grid-row-end: 11;
    grid-column-start: 16;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRightRight5 {
    grid-row-start: 4;
    grid-row-end: 11;
    grid-column-start: 29;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.CtGrasp {
    grid-row-start: 4;
    grid-row-end: 11;
    grid-column-start: 55;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRightRight9 {
    grid-row-start: 5;
    grid-row-end: 8;
    grid-column-start: 3;
    grid-column-end: 4;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRightRight10 {
    grid-row-start: 4;
    grid-row-end: 11;
    grid-column-start: 67;
    grid-column-end: 77;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRightRight11 {
    grid-row-start: 5;
    grid-row-end: 9;
    grid-column-start: 42;
    grid-column-end: 47;
    width: 100%;
    height: 100%;
}
.CtHoldCarry {
    grid-row-start: 12;
    grid-row-end: 19;
    grid-column-start: 16;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRightRight15 {
    grid-row-start: 12;
    grid-row-end: 19;
    grid-column-start: 29;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.CtRelease {
    grid-row-start: 12;
    grid-row-end: 19;
    grid-column-start: 55;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRightRight19 {
    grid-row-start: 13;
    grid-row-end: 17;
    grid-column-start: 3;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight20 {
    grid-row-start: 12;
    grid-row-end: 19;
    grid-column-start: 67;
    grid-column-end: 77;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight21 {
    grid-row-start: 13;
    grid-row-end: 16;
    grid-column-start: 43;
    grid-column-end: 50;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight22 {
    grid-row-start: 20;
    grid-row-end: 21;
    grid-column-start: 3;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.CtGraspLarge {
    grid-row-start: 23;
    grid-row-end: 30;
    grid-column-start: 16;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight27 {
    grid-row-start: 23;
    grid-row-end: 30;
    grid-column-start: 29;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.CtHookGrasp {
    grid-row-start: 23;
    grid-row-end: 30;
    grid-column-start: 55;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight31 {
    grid-row-start: 24;
    grid-row-end: 28;
    grid-column-start: 3;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight32 {
    grid-row-start: 23;
    grid-row-end: 30;
    grid-column-start: 67;
    grid-column-end: 77;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight33 {
    grid-row-start: 24;
    grid-row-end: 28;
    grid-column-start: 42;
    grid-column-end: 80;
    width: 100%;
    height: 100%;
}
.CtCylindrical {
    grid-row-start: 31;
    grid-row-end: 38;
    grid-column-start: 16;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight37 {
    grid-row-start: 31;
    grid-row-end: 38;
    grid-column-start: 29;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.CtSpherical {
    grid-row-start: 31;
    grid-row-end: 38;
    grid-column-start: 55;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight41 {
    grid-row-start: 32;
    grid-row-end: 36;
    grid-column-start: 3;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight42 {
    grid-row-start: 31;
    grid-row-end: 38;
    grid-column-start: 67;
    grid-column-end: 77;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight43 {
    grid-row-start: 32;
    grid-row-end: 36;
    grid-column-start: 42;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.CtTripodPinch {
    grid-row-start: 39;
    grid-row-end: 47;
    grid-column-start: 16;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight47 {
    grid-row-start: 39;
    grid-row-end: 47;
    grid-column-start: 29;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.CtLateralPinch {
    grid-row-start: 39;
    grid-row-end: 47;
    grid-column-start: 55;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight51 {
    grid-row-start: 40;
    grid-row-end: 45;
    grid-column-start: 2;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight52 {
    grid-row-start: 39;
    grid-row-end: 47;
    grid-column-start: 67;
    grid-column-end: 77;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight54 {
    grid-row-start: 41;
    grid-row-end: 43;
    grid-column-start: 42;
    grid-column-end: 80;
    width: 100%;
    height: 100%;
}
.CtPadToPad {
    grid-row-start: 48;
    grid-row-end: 55;
    grid-column-start: 16;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight58 {
    grid-row-start: 48;
    grid-row-end: 55;
    grid-column-start: 29;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.CtTipPinch {
    grid-row-start: 48;
    grid-row-end: 55;
    grid-column-start: 55;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight62 {
    grid-row-start: 49;
    grid-row-end: 53;
    grid-column-start: 3;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight63 {
    grid-row-start: 48;
    grid-row-end: 55;
    grid-column-start: 67;
    grid-column-end: 77;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight64 {
    grid-row-start: 49;
    grid-row-end: 53;
    grid-column-start: 42;
    grid-column-end: 80;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight65 {
    grid-row-start: 56;
    grid-row-end: 62;
    grid-column-start: 16;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight66 {
    grid-row-start: 58;
    grid-row-end: 61;
    grid-column-start: 3;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight67 {
    grid-row-start: 56;
    grid-row-end: 62;
    grid-column-start: 62;
    grid-column-end: 73;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight68 {
    grid-row-start: 58;
    grid-row-end: 62;
    grid-column-start: 36;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight69 {
    grid-row-start: 58;
    grid-row-end: 70;
    grid-column-start: 42;
    grid-column-end: 70;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight70 {
    grid-row-start: 58;
    grid-row-end: 60;
    grid-column-start: 38;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight71 {
    grid-row-start: 58;
    grid-row-end: 62;
    grid-column-start: 74;
    grid-column-end: 75;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight72 {
    grid-row-start: 58;
    grid-row-end: 60;
    grid-column-start: 75;
    grid-column-end: 78;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight73 {
    grid-row-start: 64;
    grid-row-end: 72;
    grid-column-start: 25;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight74 {
    grid-row-start: 66;
    grid-row-end: 71;
    grid-column-start: 15;
    grid-column-end: 18;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight75 {
    grid-row-start: 66;
    grid-row-end: 69;
    grid-column-start: 3;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight76 {
    grid-row-start: 64;
    grid-row-end: 72;
    grid-column-start: 61;
    grid-column-end: 68;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight77 {
    grid-row-start: 66;
    grid-row-end: 69;
    grid-column-start: 19;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight78 {
    grid-row-start: 66;
    grid-row-end: 71;
    grid-column-start: 31;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight79 {
    grid-row-start: 66;
    grid-row-end: 71;
    grid-column-start: 41;
    grid-column-end: 44;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight80 {
    grid-row-start: 66;
    grid-row-end: 68;
    grid-column-start: 32;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight81 {
    grid-row-start: 66;
    grid-row-end: 69;
    grid-column-start: 46;
    grid-column-end: 65;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight82 {
    grid-row-start: 66;
    grid-row-end: 71;
    grid-column-start: 69;
    grid-column-end: 70;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight83 {
    grid-row-start: 66;
    grid-row-end: 68;
    grid-column-start: 70;
    grid-column-end: 72;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight84 {
    grid-row-start: 73;
    grid-row-end: 80;
    grid-column-start: 25;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight85 {
    grid-row-start: 75;
    grid-row-end: 79;
    grid-column-start: 15;
    grid-column-end: 18;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight86 {
    grid-row-start: 73;
    grid-row-end: 80;
    grid-column-start: 61;
    grid-column-end: 68;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight87 {
    grid-row-start: 75;
    grid-row-end: 78;
    grid-column-start: 20;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight88 {
    grid-row-start: 75;
    grid-row-end: 79;
    grid-column-start: 31;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight89 {
    grid-row-start: 75;
    grid-row-end: 79;
    grid-column-start: 41;
    grid-column-end: 44;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight90 {
    grid-row-start: 75;
    grid-row-end: 77;
    grid-column-start: 32;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight91 {
    grid-row-start: 75;
    grid-row-end: 78;
    grid-column-start: 45;
    grid-column-end: 80;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight92 {
    grid-row-start: 75;
    grid-row-end: 79;
    grid-column-start: 69;
    grid-column-end: 70;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight93 {
    grid-row-start: 75;
    grid-row-end: 77;
    grid-column-start: 70;
    grid-column-end: 72;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight94 {
    grid-row-start: 81;
    grid-row-end: 84;
    grid-column-start: 9;
    grid-column-end: 79;
    width: 100%;
    height: 100%;
}
.OtPhysicalDysfunctionHandFunctionRight95 {
    grid-row-start: 82;
    grid-row-end: 83;
    grid-column-start: 2;
    grid-column-end: 5;
    width: 100%;
    height: 100%;
}
