
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 1.3812154696132597% 0.5524861878453039% 1.3812154696132597% 2.4861878453038675% 0.8287292817679558% 3.591160220994475% 0.8287292817679558% 1.9337016574585635% 1.1049723756906078% 2.2099447513812156% 0.5524861878453039% 0.8287292817679558% 1.6574585635359116% 1.3812154696132597% 0.27624309392265195% 3.591160220994475% 0.8287292817679558% 0.27624309392265195% 1.1049723756906078% 0.5524861878453039% 0.8287292817679558% 1.9337016574585635% 0.5524861878453039% 0.27624309392265195% 0.5524861878453039% 0.27624309392265195% 1.1049723756906078% 0.5524861878453039% 0.27624309392265195% 0.5524861878453039% 0.27624309392265195% 0.5524861878453039% 1.6574585635359116% 0.5524861878453039% 0.8287292817679558% 1.6574585635359116% 1.3812154696132597% 0.27624309392265195% 3.0386740331491713% 0.5524861878453039% 0.8287292817679558% 1.6574585635359116% 0.27624309392265195% 1.1049723756906078% 0.27624309392265195% 3.867403314917127% 1.3812154696132597% 0.5524861878453039% 0.5524861878453039% 0.8287292817679558% 1.3812154696132597% 3.0386740331491713% 0.8287292817679558% 0.27624309392265195% 1.9337016574585635% 0.8287292817679558% 2.7624309392265194% 1.3812154696132597% 2.4861878453038675% 9.116022099447514% 4.419889502762431% 14.917127071823204% 0.27624309392265195%;
    grid-template-columns: 0.11049723756906077% 1.4364640883977902% 0.11049723756906077% 0.11049723756906077% 4.530386740331492% 0.5524861878453039% 0.8839779005524862% 1.3259668508287292% 1.5469613259668509% 0.11049723756906077% 0.5524861878453039% 0.8839779005524862% 0.5524861878453039% 0.11049723756906077% 0.11049723756906077% 1.2154696132596685% 0.7734806629834254% 0.7734806629834254% 0.5524861878453039% 0.22099447513812154% 1.4364640883977902% 0.3314917127071823% 1.8784530386740332% 3.6464088397790055% 0.7734806629834254% 0.5524861878453039% 0.22099447513812154% 0.6629834254143646% 0.5524861878453039% 1.8784530386740332% 0.5524861878453039% 0.11049723756906077% 0.5524861878453039% 1.7679558011049723% 2.541436464088398% 0.11049723756906077% 1.3259668508287292% 1.1049723756906078% 0.994475138121547% 1.2154696132596685% 0.22099447513812154% 0.7734806629834254% 0.4419889502762431% 0.994475138121547% 2.983425414364641% 0.7734806629834254% 1.4364640883977902% 0.7734806629834254% 1.5469613259668509% 1.4364640883977902% 0.7734806629834254% 0.11049723756906077% 1.7679558011049723% 0.11049723756906077% 1.1049723756906078% 0.8839779005524862% 1.2154696132596685% 1.4364640883977902% 0.7734806629834254% 4.088397790055248% 1.4364640883977902% 0.7734806629834254% 2.2099447513812156% 3.867403314917127% 1.1049723756906078% 0.11049723756906077% 0.22099447513812154% 0.7734806629834254% 2.2099447513812156% 1.4364640883977902% 0.6629834254143646% 0.11049723756906077% 3.7569060773480665% 0.4419889502762431% 0.7734806629834254% 2.320441988950276% 1.4364640883977902% 0.6629834254143646% 0.11049723756906077% 2.7624309392265194% 1.2154696132596685% 4.088397790055248% 3.7569060773480665% 0.5524861878453039% 0.22099447513812154% 0.5524861878453039% 0.11049723756906077%;
}

.PtDiagnosisOrthoOpdipdLow1 {
    grid-row-start: 1;
    grid-row-end: 63;
    grid-column-start: 2;
    grid-column-end: 87;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow2 {
    grid-row-start: 4;
    grid-row-end: 7;
    grid-column-start: 10;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow3 {
    grid-row-start: 4;
    grid-row-end: 7;
    grid-column-start: 49;
    grid-column-end: 84;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow4 {
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 3;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 63;
    grid-row-end: 63;
    grid-column-start: 78;
    grid-column-end: 87;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow6 {
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 39;
    grid-column-end: 48;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow7 {
    grid-row-start: 9;
    grid-row-end: 11;
    grid-column-start: 11;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow8 {
    grid-row-start: 9;
    grid-row-end: 11;
    grid-column-start: 25;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow9 {
    grid-row-start: 9;
    grid-row-end: 10;
    grid-column-start: 3;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow10 {
    grid-row-start: 9;
    grid-row-end: 11;
    grid-column-start: 41;
    grid-column-end: 44;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow11 {
    grid-row-start: 9;
    grid-row-end: 10;
    grid-column-start: 15;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow12 {
    grid-row-start: 9;
    grid-row-end: 11;
    grid-column-start: 58;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow13 {
    grid-row-start: 9;
    grid-row-end: 10;
    grid-column-start: 29;
    grid-column-end: 40;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow15 {
    grid-row-start: 9;
    grid-row-end: 11;
    grid-column-start: 65;
    grid-column-end: 68;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow16 {
    grid-row-start: 9;
    grid-row-end: 10;
    grid-column-start: 45;
    grid-column-end: 58;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow17 {
    grid-row-start: 9;
    grid-row-end: 11;
    grid-column-start: 77;
    grid-column-end: 78;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow18 {
    grid-row-start: 9;
    grid-row-end: 10;
    grid-column-start: 60;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow19 {
    grid-row-start: 9;
    grid-row-end: 10;
    grid-column-start: 69;
    grid-column-end: 77;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow20 {
    grid-row-start: 9;
    grid-row-end: 10;
    grid-column-start: 79;
    grid-column-end: 84;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow21 {
    grid-row-start: 12;
    grid-row-end: 16;
    grid-column-start: 20;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow22 {
    grid-row-start: 13;
    grid-row-end: 16;
    grid-column-start: 11;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow23 {
    grid-row-start: 13;
    grid-row-end: 15;
    grid-column-start: 15;
    grid-column-end: 18;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow24 {
    grid-row-start: 18;
    grid-row-end: 23;
    grid-column-start: 11;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow25 {
    grid-row-start: 18;
    grid-row-end: 23;
    grid-column-start: 25;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow26 {
    grid-row-start: 18;
    grid-row-end: 23;
    grid-column-start: 40;
    grid-column-end: 42;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow27 {
    grid-row-start: 18;
    grid-row-end: 22;
    grid-column-start: 4;
    grid-column-end: 7;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow28 {
    grid-row-start: 18;
    grid-row-end: 21;
    grid-column-start: 16;
    grid-column-end: 18;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow29 {
    grid-row-start: 18;
    grid-row-end: 23;
    grid-column-start: 50;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow30 {
    grid-row-start: 18;
    grid-row-end: 22;
    grid-column-start: 29;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow31 {
    grid-row-start: 18;
    grid-row-end: 23;
    grid-column-start: 61;
    grid-column-end: 62;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow32 {
    grid-row-start: 18;
    grid-row-end: 22;
    grid-column-start: 43;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow33 {
    grid-row-start: 18;
    grid-row-end: 23;
    grid-column-start: 70;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow34 {
    grid-row-start: 18;
    grid-row-end: 21;
    grid-column-start: 52;
    grid-column-end: 61;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow35 {
    grid-row-start: 18;
    grid-row-end: 23;
    grid-column-start: 77;
    grid-column-end: 78;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow36 {
    grid-row-start: 18;
    grid-row-end: 21;
    grid-column-start: 63;
    grid-column-end: 70;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow37 {
    grid-row-start: 18;
    grid-row-end: 22;
    grid-column-start: 73;
    grid-column-end: 76;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow38 {
    grid-row-start: 18;
    grid-row-end: 22;
    grid-column-start: 80;
    grid-column-end: 82;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow39 {
    grid-row-start: 26;
    grid-row-end: 32;
    grid-column-start: 11;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow40 {
    grid-row-start: 24;
    grid-row-end: 34;
    grid-column-start: 58;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow41 {
    grid-row-start: 26;
    grid-row-end: 32;
    grid-column-start: 25;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow42 {
    grid-row-start: 26;
    grid-row-end: 32;
    grid-column-start: 40;
    grid-column-end: 42;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow43 {
    grid-row-start: 26;
    grid-row-end: 30;
    grid-column-start: 15;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow44 {
    grid-row-start: 26;
    grid-row-end: 32;
    grid-column-start: 50;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow45 {
    grid-row-start: 26;
    grid-row-end: 30;
    grid-column-start: 29;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow46 {
    grid-row-start: 26;
    grid-row-end: 32;
    grid-column-start: 70;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow47 {
    grid-row-start: 26;
    grid-row-end: 30;
    grid-column-start: 43;
    grid-column-end: 50;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow48 {
    grid-row-start: 26;
    grid-row-end: 32;
    grid-column-start: 77;
    grid-column-end: 78;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow49 {
    grid-row-start: 26;
    grid-row-end: 30;
    grid-column-start: 52;
    grid-column-end: 56;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow50 {
    grid-row-start: 26;
    grid-row-end: 30;
    grid-column-start: 72;
    grid-column-end: 74;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow51 {
    grid-row-start: 26;
    grid-row-end: 29;
    grid-column-start: 80;
    grid-column-end: 81;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow52 {
    grid-row-start: 35;
    grid-row-end: 39;
    grid-column-start: 20;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow53 {
    grid-row-start: 36;
    grid-row-end: 39;
    grid-column-start: 11;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow54 {
    grid-row-start: 36;
    grid-row-end: 38;
    grid-column-start: 15;
    grid-column-end: 18;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow55 {
    grid-row-start: 41;
    grid-row-end: 46;
    grid-column-start: 17;
    grid-column-end: 48;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow56 {
    grid-row-start: 42;
    grid-row-end: 44;
    grid-column-start: 5;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow57 {
    grid-row-start: 42;
    grid-row-end: 46;
    grid-column-start: 50;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow58 {
    grid-row-start: 42;
    grid-row-end: 44;
    grid-column-start: 53;
    grid-column-end: 54;
    width: 100%;
    height: 100%;
}
.Ct6Premorbid {
    grid-row-start: 48;
    grid-row-end: 53;
    grid-column-start: 17;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow62 {
    grid-row-start: 48;
    grid-row-end: 53;
    grid-column-start: 31;
    grid-column-end: 84;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow63 {
    grid-row-start: 49;
    grid-row-end: 52;
    grid-column-start: 5;
    grid-column-end: 6;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow64 {
    grid-row-start: 54;
    grid-row-end: 58;
    grid-column-start: 11;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow65 {
    grid-row-start: 54;
    grid-row-end: 58;
    grid-column-start: 25;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow66 {
    grid-row-start: 54;
    grid-row-end: 57;
    grid-column-start: 5;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow67 {
    grid-row-start: 54;
    grid-row-end: 57;
    grid-column-start: 15;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow68 {
    grid-row-start: 54;
    grid-row-end: 57;
    grid-column-start: 29;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow69 {
    grid-row-start: 59;
    grid-row-end: 62;
    grid-column-start: 18;
    grid-column-end: 84;
    width: 100%;
    height: 100%;
}
.PtDiagnosisOrthoOpdipdLow70 {
    grid-row-start: 60;
    grid-row-end: 61;
    grid-column-start: 4;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
