
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 2.803738317757009% 13.08411214953271% 0.9345794392523364% 0.9345794392523364% 1.8691588785046729% 1.8691588785046729% 0.9345794392523364% 0.9345794392523364% 1.8691588785046729% 1.8691588785046729% 0.9345794392523364% 3.7383177570093458% 12.149532710280374% 4.672897196261682% 0.9345794392523364% 0.9345794392523364% 2.803738317757009% 0.9345794392523364% 0.9345794392523364% 1.8691588785046729% 2.803738317757009% 14.018691588785046% 2.803738317757009% 0.9345794392523364% 0.9345794392523364% 0.9345794392523364% 4.672897196261682% 1.8691588785046729% 1.8691588785046729% 1.8691588785046729% 10.280373831775702% 0.0%;
    grid-template-columns: 0.0% 0.1111111111111111% 0.5555555555555556% 1.4444444444444444% 6.222222222222222% 0.5555555555555556% 2.0% 1.8888888888888888% 0.1111111111111111% 1.1111111111111112% 0.1111111111111111% 0.7777777777777778% 1.0% 1.7777777777777777% 1.1111111111111112% 4.444444444444445% 0.4444444444444444% 0.8888888888888888% 5.0% 1.4444444444444444% 1.6666666666666667% 1.3333333333333333% 0.7777777777777778% 1.6666666666666667% 2.111111111111111% 0.5555555555555556% 0.6666666666666666% 2.0% 4.777777777777778% 2.4444444444444446% 0.2222222222222222% 0.2222222222222222% 0.5555555555555556% 0.3333333333333333% 1.1111111111111112% 1.1111111111111112% 1.4444444444444444% 0.6666666666666666% 1.1111111111111112% 1.2222222222222223% 1.7777777777777777% 0.6666666666666666% 1.3333333333333333% 0.4444444444444444% 0.3333333333333333% 0.4444444444444444% 0.3333333333333333% 0.7777777777777778% 1.2222222222222223% 2.0% 5.333333333333333% 1.1111111111111112% 0.6666666666666666% 0.5555555555555556% 0.1111111111111111% 0.3333333333333333% 0.3333333333333333% 4.111111111111111% 1.2222222222222223% 5.666666666666667% 0.6666666666666666% 1.0% 2.6666666666666665% 2.2222222222222223% 1.7777777777777777% 1.4444444444444444% 0.7777777777777778% 0.5555555555555556% 0.4444444444444444% 0.3333333333333333% 0.3333333333333333% 1.2222222222222223% 0.4444444444444444% 0.3333333333333333% 0.0%;
}

.PoPhysicalAnkleFootOrthosisTop2 {
    grid-row-start: 3;
    grid-row-end: 33;
    grid-column-start: 4;
    grid-column-end: 73;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop3 {
    grid-row-start: 4;
    grid-row-end: 14;
    grid-column-start: 10;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop4 {
    grid-row-start: 7;
    grid-row-end: 12;
    grid-column-start: 5;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.CtGeneralApperanceDysfunction {
    grid-row-start: 5;
    grid-row-end: 14;
    grid-column-start: 32;
    grid-column-end: 47;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop8 {
    grid-row-start: 6;
    grid-row-end: 14;
    grid-column-start: 48;
    grid-column-end: 61;
    width: 100%;
    height: 100%;
}
.Ct4GeneralApperance {
    grid-row-start: 6;
    grid-row-end: 14;
    grid-column-start: 62;
    grid-column-end: 72;
    width: 100%;
    height: 100%;
}
.Ct4Consciousness {
    grid-row-start: 15;
    grid-row-end: 23;
    grid-column-start: 10;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.Ct7Communication {
    grid-row-start: 15;
    grid-row-end: 23;
    grid-column-start: 26;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.Ct7CoOperation {
    grid-row-start: 15;
    grid-row-end: 23;
    grid-column-start: 49;
    grid-column-end: 58;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop21 {
    grid-row-start: 16;
    grid-row-end: 20;
    grid-column-start: 5;
    grid-column-end: 7;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop22 {
    grid-row-start: 16;
    grid-row-end: 20;
    grid-column-start: 20;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop23 {
    grid-row-start: 16;
    grid-row-end: 22;
    grid-column-start: 40;
    grid-column-end: 49;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop24 {
    grid-row-start: 24;
    grid-row-end: 32;
    grid-column-start: 16;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop25 {
    grid-row-start: 24;
    grid-row-end: 32;
    grid-column-start: 28;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop26 {
    grid-row-start: 25;
    grid-row-end: 31;
    grid-column-start: 9;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop27 {
    grid-row-start: 25;
    grid-row-end: 30;
    grid-column-start: 5;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop28 {
    grid-row-start: 24;
    grid-row-end: 32;
    grid-column-start: 44;
    grid-column-end: 52;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop29 {
    grid-row-start: 25;
    grid-row-end: 31;
    grid-column-start: 22;
    grid-column-end: 23;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop30 {
    grid-row-start: 25;
    grid-row-end: 29;
    grid-column-start: 13;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop31 {
    grid-row-start: 24;
    grid-row-end: 32;
    grid-column-start: 60;
    grid-column-end: 65;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop32 {
    grid-row-start: 25;
    grid-row-end: 31;
    grid-column-start: 37;
    grid-column-end: 38;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop33 {
    grid-row-start: 25;
    grid-row-end: 30;
    grid-column-start: 24;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop34 {
    grid-row-start: 25;
    grid-row-end: 31;
    grid-column-start: 53;
    grid-column-end: 55;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop35 {
    grid-row-start: 25;
    grid-row-end: 30;
    grid-column-start: 39;
    grid-column-end: 42;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop36 {
    grid-row-start: 25;
    grid-row-end: 31;
    grid-column-start: 66;
    grid-column-end: 67;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop37 {
    grid-row-start: 25;
    grid-row-end: 29;
    grid-column-start: 57;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.PoPhysicalAnkleFootOrthosisTop38 {
    grid-row-start: 25;
    grid-row-end: 29;
    grid-column-start: 68;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
