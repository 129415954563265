/* CardCSS.scss */
.card-cs {
  padding: 4px;
  margin-bottom: 3px;
  background-color: #F2F2F2;
  border-radius: 4px;
  .division-label {
    color: #2f80ed;
    font-weight: bold;
  }
  .cs-rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
}

.card-cs.card-encounter,
.card-cs.card-cs-medication,
.card-cs.card-pending-order, 
.card-cs.card-diagnosis,
.card-cs.card-replyconsult,
.card-cs.card-procedure-detail,
.card-cs.card-cs-patient-care {
  height: 330px;
}

.card-cs.card-lab {
  height: 660px;
  .cs-xray-type {
    font-weight: bold;
    font-size: larger
  }
  .cs-lab-type {
    font-weight: bold;
    font-size: larger;
    margin-bottom: 6px;
   
  }
  .cs-lab-code {
    font-weight: normal;
    font-size: small;
  }

  .cs-child-lab-row {
    display: flex;
    justify-content: space-between;

    .cs-lab-name {
      display: flex;
      width: 50% ;
      margin-bottom: 4px;
      .cs-lab-title {
        color: #4c4c4c;
        display: flex;
        flex-direction: column;
      }
    }

    .cs-lab-value{ 
      display:flex;
      width: 50%;
      color: #4c4c4c;
    }
  }

  .cs-confidential-color {
    color: orange;
  }
  .cs-normal-range-color {
    color: darkcyan;
  }

  .cs-shrink-0 {
    flex-shrink: 0;
  }
  .cs-shrink-1 {
    flex-shrink: 1;
  }
  .cs-bold {
    font-weight: bold;
  }
  .cs-bolder {
    font-weight: bolder;
  }
  .cs-flex {
    display: flex;
  }
  .cs-break-word {
    word-break: break-word;
  }
  .cs-text-align-center {
    text-align: center;
  }
  .cs-margin-top {
    margin-top: 20px
  }
  
}

.card-cs.card-encounter,
.card-cs.card-lab,
.card-cs.card-diagnosis,
.card-cs.card-pending-order,
.card-cs.card-replyconsult,
.card-cs.card-procedure-detail{

  display:flex;
  flex-direction: column;
  
  .card-cs-header { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4px;

    .card-cs-header-group-button {
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding-right: 10px;
    }

    .button {
      background-color: gray;
      color: white;
      padding: 5px;
    }
    .button:hover {
      color: white;
    }
    .button.active {
      background-color: #0B558C;
    }
  }

  .card-cs-header-title {
    font-size: smaller;
    font-weight: bold;
    margin-right: 4px;
  }

  .card-cs-division-search {
    width: 100%;
    margin-right: 4px;
    min-width: 60px
  }

  .card-cs-doctor-search {
    width: 100%;
    min-width: 100px;
    margin-right: 4px;
  }
  
  .card-wrap-cs-table {
    min-height:0;
    flex-shrink: 1;
    flex-grow:1 ;
  }

  .card-cs-table {
    .rt-thead.-header {
      background-color: #0B558C;
    }
  }

  .card-cs-table-none-border {
    .rt-thead.-header {
      background-color: #0B558C;
    }
    .rt-body, .rt-tr-group{
      border-bottom: none;
    }
  }
}

.card-cs.card-cs-medication,
.card-cs.card-cs-patient-care{
  .patient-card-header{
    font-size: smaller;
    font-weight: bold;
    padding: 0px 0px 6px 5px;
  }
}

.card-cs.card-cs-medication {
  padding: 11px;
  display: flex;
  flex-direction: column;

  .sheet{
    padding: 15px;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background-color: #FFFFFF;
  
    .row{
      display: flex;
      width: 100%;
      flex-direction: row;
      align-self: center;
      padding: 7px 0px 7px 0px;
        
      .row-left{
        text-align: left;
        font-size: 15px;
        color: #4F4F4F;
        width: 60%
      }
  
      .row-right{
        text-align: right;
        font-size: 15px;
        color: #4F4F4F;
        width: 40%
      }
    }
    .row-no-data{
      width: 100%;
      height: 100%;
    }
  }
}

.card-cs.card-cs-patient-care {
  background-color: #F2F2F2;
  padding: 11px;
  display: flex;
  flex-direction: column;

  .patient-flow-sheet{
    padding: 15px;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background-color: #FFFFFF;

    .row-content-header{
      display: flex;
      width: 100%;
      flex-direction: row;
      font-weight: bold;
      padding-bottom: 10px;

      .row-left{
        width: 50%;
        padding-left: 5px;
      }
    }
    .row-content-text{
      padding: 3px 3px 3px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 0.5px solid lightgray;
      font-weight: bold;
      div {
        width: 40%;
        color: #2e2e2e;
      }
      .row-right{
        width: 40%;
        color: #2F80ED;
        font-weight: normal;
      }
    }

    .row-content-text:nth-child(even){ 
      background-color: #e1e1e1;
      .row-right{
        color: #1e57a3;
      }
    }
  }
}