#main-scn {
    .water-mark-1 {
        clear: both;
        width: max-content;
        height: max-content;
        position: absolute;
        top: 35%;
        left: 50%;
        color: rgba(178, 178, 178, 0.5);
        font-size: 23px;
        transform: translateX(-50%) translateY(-50%) rotate(310deg);
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(310deg);
        background-color: transparent;
        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE10+/Edge */
        user-select: none;
        /* Standard */
    }
    .water-mark-2 {
        clear: both;
        width: max-content;
        height: max-content;
        position: absolute;
        top: 75%;
        left: 50%;
        color: rgba(178, 178, 178, 0.5);
        font-size: 23px;
        transform: translateX(-50%) translateY(-50%) rotate(310deg);
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(310deg);
        background-color: transparent;
        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE10+/Edge */
        user-select: none;
        /* Standard */
    }
    /*issue 52820*/
    zoom: 75%;
}

// issue 53774
@media only screen and (max-width: 1048px) {
    #main-scn {
        zoom: 70%;
        .BarcodeScan {
            .ui.segment.grey.inverted {
                transform: scale(0.8);
                width: 125% !important;
                margin-left: -12.5%;
                height: 125% !important;
                margin-top: -17%;
                overflow: hidden;
            }
        }
        .NewScan {
            .ui.segment.grey.inverted {
                transform: scale(0.6);
                width: 166% !important;
                margin-left: -33%;
                height: 167% !important;
                margin-top: -62%;
            }
            .ReactTable {
                zoom: 90%;
            }
            .ui.form .two.wide.field .ui.green.button {
                padding: 9px 12px !important;
            }
        }
        .DayPickerInput-OverlayWrapper {
            margin-left: -2.4em;
            .DayPicker-Day,
            .DayPicker-WeekNumber {
                padding-top: 0.1em;
                padding-bottom: 0.1em;
            }
        }
    }
    .modal-search-encounter {
        width: 95% !important;
    }
}