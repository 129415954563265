.canvas-container {
  margin: 0 auto;
}

.edit-image-color-picker {
  width: 513px;
  background: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;
  border-radius: 4px;
  position: relative;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}

.edit-image-color-picker .group-color {
  width: 30px;
  height: 30px;
  font-size: 0px;
}

.edit-image-color-picker .group-color .box-color {
  background: rgb(255, 201, 14);
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  outline: none;
}

.edit-image-color-picker .group-color .box-color.active {
  outline: rgb(255, 255, 255) solid 2px;
  z-index: 2;
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 5px 3px;
}

/* issue 53771 */
/* (1024x768) iPad 1 & 2, XGA */
@media only screen and (max-width: 1048px) {
  .form-custom-fabric {
    padding: 10px 0px 0px 10px !important;
  }
  .form-custom-fabric .label-edit {
    width: 120px !important;
  }
  .form-custom-fabric .edit-image-color-picker {
    width: 75% !important;
    transform: scale(0.9);
    margin: 0 -25px 0 -9px !important;
  }
  .form-custom-fabric .button-toggle-baby-teeth {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-custom-fabric .button-toggle-baby-teeth button {
    width: 31px;
  }
  .form-custom-fabric .button-toggle-baby-teeth span {
    width: 125px;
  }
  .form-custom-fabric > div:nth-child(1) {
    margin-bottom: 10px !important;
  }
  .modal-edit-image .close.icon {
    top: 0 !important;
    right: 0 !important;
  }
  .modal-edit-image .ui.attached.tabular.menu {
    zoom: 80%;
  }
}
