
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 7.6923076923076925% 2.197802197802198% 0.5494505494505495% 1.098901098901099% 1.098901098901099% 1.098901098901099% 3.2967032967032965% 3.8461538461538463% 2.7472527472527473% 0.5494505494505495% 1.098901098901099% 1.098901098901099% 1.098901098901099% 1.098901098901099% 2.197802197802198% 3.8461538461538463% 3.2967032967032965% 1.098901098901099% 1.098901098901099% 1.098901098901099% 0.5494505494505495% 1.098901098901099% 1.6483516483516483% 4.395604395604396% 1.6483516483516483% 0.5494505494505495% 0.5494505494505495% 0.5494505494505495% 0.5494505494505495% 1.6483516483516483% 0.5494505494505495% 0.5494505494505495% 0.5494505494505495% 0.5494505494505495% 1.6483516483516483% 1.098901098901099% 2.197802197802198% 2.7472527472527473% 0.5494505494505495% 0.5494505494505495% 1.6483516483516483% 0.5494505494505495% 1.098901098901099% 2.197802197802198% 1.098901098901099% 1.6483516483516483% 3.2967032967032965% 0.5494505494505495% 0.5494505494505495% 1.098901098901099% 1.098901098901099% 0.5494505494505495% 2.7472527472527473% 1.098901098901099% 3.8461538461538463% 1.098901098901099% 0.5494505494505495% 2.7472527472527473% 0.5494505494505495% 0.5494505494505495% 1.6483516483516483% 0.5494505494505495% 3.8461538461538463% 0.0%;
    grid-template-columns: 0.10989010989010989% 1.978021978021978% 0.10989010989010989% 5.274725274725275% 0.8791208791208791% 0.5494505494505495% 0.43956043956043955% 1.6483516483516483% 1.8681318681318682% 1.098901098901099% 0.10989010989010989% 0.21978021978021978% 0.6593406593406593% 0.10989010989010989% 0.8791208791208791% 0.7692307692307693% 0.7692307692307693% 1.098901098901099% 1.098901098901099% 3.076923076923077% 0.5494505494505495% 0.43956043956043955% 0.8791208791208791% 0.5494505494505495% 0.10989010989010989% 0.43956043956043955% 0.5494505494505495% 0.43956043956043955% 0.32967032967032966% 0.43956043956043955% 2.087912087912088% 0.21978021978021978% 1.2087912087912087% 0.10989010989010989% 0.10989010989010989% 0.8791208791208791% 0.32967032967032966% 0.10989010989010989% 0.21978021978021978% 1.4285714285714286% 0.6593406593406593% 1.7582417582417582% 2.087912087912088% 0.21978021978021978% 1.098901098901099% 0.21978021978021978% 0.5494505494505495% 0.7692307692307693% 0.32967032967032966% 0.7692307692307693% 0.10989010989010989% 1.2087912087912087% 2.7472527472527473% 0.7692307692307693% 1.2087912087912087% 0.43956043956043955% 0.5494505494505495% 0.7692307692307693% 0.21978021978021978% 0.7692307692307693% 0.21978021978021978% 1.098901098901099% 0.43956043956043955% 0.43956043956043955% 0.10989010989010989% 0.43956043956043955% 0.32967032967032966% 0.32967032967032966% 0.10989010989010989% 0.10989010989010989% 0.8791208791208791% 1.978021978021978% 0.5494505494505495% 0.5494505494505495% 0.10989010989010989% 0.5494505494505495% 2.087912087912088% 1.5384615384615385% 1.2087912087912087% 1.978021978021978% 5.824175824175824% 0.6593406593406593% 0.7692307692307693% 0.5494505494505495% 0.10989010989010989% 0.6593406593406593% 0.10989010989010989% 4.395604395604396% 0.7692307692307693% 12.637362637362637% 1.4285714285714286% 1.4285714285714286% 0.7692307692307693% 1.4285714285714286% 1.978021978021978% 0.0%;
}

.PtPhysicalNeuroOpdTop1 {
    grid-row-start: 1;
    grid-row-end: 65;
    grid-column-start: 2;
    grid-column-end: 96;
    width: 100%;
    height: 100%;
}
.Ct4Observation {
    grid-row-start: 3;
    grid-row-end: 9;
    grid-column-start: 10;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop5 {
    grid-row-start: 3;
    grid-row-end: 9;
    grid-column-start: 29;
    grid-column-end: 54;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop6 {
    grid-row-start: 4;
    grid-row-end: 8;
    grid-column-start: 4;
    grid-column-end: 5;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop7 {
    grid-row-start: 10;
    grid-row-end: 16;
    grid-column-start: 10;
    grid-column-end: 54;
    width: 100%;
    height: 100%;
}
.Ct9GeneralAppearance {
    grid-row-start: 10;
    grid-row-end: 17;
    grid-column-start: 55;
    grid-column-end: 75;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop11 {
    grid-row-start: 12;
    grid-row-end: 16;
    grid-column-start: 4;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.Ct11Consciousness {
    grid-row-start: 18;
    grid-row-end: 25;
    grid-column-start: 10;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.Ct14Communication {
    grid-row-start: 18;
    grid-row-end: 25;
    grid-column-start: 44;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.Ct14CoOperation {
    grid-row-start: 18;
    grid-row-end: 25;
    grid-column-start: 79;
    grid-column-end: 87;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop21 {
    grid-row-start: 19;
    grid-row-end: 22;
    grid-column-start: 3;
    grid-column-end: 7;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop22 {
    grid-row-start: 19;
    grid-row-end: 22;
    grid-column-start: 32;
    grid-column-end: 43;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop23 {
    grid-row-start: 19;
    grid-row-end: 24;
    grid-column-start: 72;
    grid-column-end: 79;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop24 {
    grid-row-start: 27;
    grid-row-end: 35;
    grid-column-start: 10;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.Ct2BedMobilityTestRolling {
    grid-row-start: 26;
    grid-row-end: 37;
    grid-column-start: 33;
    grid-column-end: 49;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop28 {
    grid-row-start: 26;
    grid-row-end: 37;
    grid-column-start: 53;
    grid-column-end: 72;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop31 {
    grid-row-start: 27;
    grid-row-end: 34;
    grid-column-start: 4;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop32 {
    grid-row-start: 27;
    grid-row-end: 32;
    grid-column-start: 14;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop33 {
    grid-row-start: 27;
    grid-row-end: 33;
    grid-column-start: 21;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop34 {
    grid-row-start: 39;
    grid-row-end: 47;
    grid-column-start: 53;
    grid-column-end: 72;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop36 {
    grid-row-start: 39;
    grid-row-end: 46;
    grid-column-start: 33;
    grid-column-end: 49;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop39 {
    grid-row-start: 41;
    grid-row-end: 45;
    grid-column-start: 21;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop40 {
    grid-row-start: 48;
    grid-row-end: 55;
    grid-column-start: 53;
    grid-column-end: 72;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop42 {
    grid-row-start: 48;
    grid-row-end: 55;
    grid-column-start: 33;
    grid-column-end: 49;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop45 {
    grid-row-start: 49;
    grid-row-end: 53;
    grid-column-start: 21;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop46 {
    grid-row-start: 57;
    grid-row-end: 63;
    grid-column-start: 20;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop47 {
    grid-row-start: 57;
    grid-row-end: 63;
    grid-column-start: 46;
    grid-column-end: 62;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop48 {
    grid-row-start: 57;
    grid-row-end: 63;
    grid-column-start: 78;
    grid-column-end: 82;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop49 {
    grid-row-start: 57;
    grid-row-end: 63;
    grid-column-start: 90;
    grid-column-end: 91;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop50 {
    grid-row-start: 58;
    grid-row-end: 63;
    grid-column-start: 10;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop51 {
    grid-row-start: 58;
    grid-row-end: 61;
    grid-column-start: 4;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop52 {
    grid-row-start: 58;
    grid-row-end: 63;
    grid-column-start: 40;
    grid-column-end: 41;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop53 {
    grid-row-start: 58;
    grid-row-end: 60;
    grid-column-start: 15;
    grid-column-end: 18;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop54 {
    grid-row-start: 58;
    grid-row-end: 63;
    grid-column-start: 63;
    grid-column-end: 67;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop55 {
    grid-row-start: 58;
    grid-row-end: 61;
    grid-column-start: 42;
    grid-column-end: 44;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop56 {
    grid-row-start: 58;
    grid-row-end: 63;
    grid-column-start: 83;
    grid-column-end: 85;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop57 {
    grid-row-start: 58;
    grid-row-end: 61;
    grid-column-start: 69;
    grid-column-end: 75;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop58 {
    grid-row-start: 58;
    grid-row-end: 63;
    grid-column-start: 92;
    grid-column-end: 93;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop59 {
    grid-row-start: 58;
    grid-row-end: 60;
    grid-column-start: 86;
    grid-column-end: 89;
    width: 100%;
    height: 100%;
}
.PtPhysicalNeuroOpdTop60 {
    grid-row-start: 58;
    grid-row-end: 60;
    grid-column-start: 94;
    grid-column-end: 95;
    width: 100%;
    height: 100%;
}
