// DateTextBox8.scss


.popup.date-text-box-8 {
    --rdp-cell-size: 30px;
    --rdp-accent-color: var(--primary-theme-color);
    --rdp-background-color: #e7edff;
    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: var(--primary-theme-color);
    --rdp-background-color-dark: #180270;
    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid var(--primary-theme-color);

    &.visible {
        padding: 5px;
        margin: 0px;
    }
    
    .rdp {
        margin: 0em;
        // width: 250px;  // for v.8.0.7
        // height: 260px; // for v.8.0.7
    }
    .rdp-month.rdp-caption_start.rdp-caption_end {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .rdp-caption {
        margin: 8px 0px;
        justify-content: flex-end;
    }
    .rdp-table {
        align-self: center;
    }
    .rdp-head_cell {
        font-size: 1em;
    }
}
