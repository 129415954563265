
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 1.050420168067227% 1.050420168067227% 1.050420168067227% 0.21008403361344538% 1.050420168067227% 7.352941176470588% 1.050420168067227% 1.2605042016806722% 5.46218487394958% 2.9411764705882355% 0.6302521008403361% 0.42016806722689076% 0.6302521008403361% 1.2605042016806722% 6.932773109243698% 0.6302521008403361% 4.411764705882353% 0.42016806722689076% 0.21008403361344538% 5.2521008403361344% 1.050420168067227% 3.361344537815126% 1.050420168067227% 1.050420168067227% 7.983193277310924% 1.050420168067227% 1.050420168067227% 1.2605042016806722% 1.2605042016806722% 0.8403361344537815% 1.050420168067227% 5.882352941176471% 0.42016806722689076% 0.21008403361344538% 4.831932773109243% 0.42016806722689076% 6.092436974789916% 0.21008403361344538% 1.050420168067227% 1.050420168067227% 4.411764705882353% 1.4705882352941178% 1.680672268907563% 1.050420168067227% 1.680672268907563% 2.73109243697479% 1.050420168067227% 1.050420168067227% 0.42016806722689076%;
    grid-template-columns: 0.0% 1.5923566878980893% 1.5923566878980893% 8.598726114649681% 2.5477707006369426% 0.3184713375796178% 1.2738853503184713% 0.6369426751592356% 7.961783439490445% 0.3184713375796178% 8.598726114649681% 0.3184713375796178% 1.2738853503184713% 0.3184713375796178% 1.2738853503184713% 0.3184713375796178% 0.6369426751592356% 0.3184713375796178% 1.2738853503184713% 0.3184713375796178% 1.5923566878980893% 0.3184713375796178% 1.5923566878980893% 2.5477707006369426% 0.6369426751592356% 0.3184713375796178% 0.6369426751592356% 1.5923566878980893% 0.6369426751592356% 0.9554140127388535% 0.6369426751592356% 4.45859872611465% 0.6369426751592356% 0.9554140127388535% 0.6369426751592356% 1.5923566878980893% 0.6369426751592356% 0.6369426751592356% 3.5031847133757963% 2.5477707006369426% 0.6369426751592356% 0.9554140127388535% 0.6369426751592356% 0.6369426751592356% 0.3184713375796178% 1.2738853503184713% 0.3184713375796178% 1.2738853503184713% 0.3184713375796178% 9.235668789808917% 0.3184713375796178% 0.9554140127388535% 1.5923566878980893% 2.229299363057325% 7.643312101910828% 1.5923566878980893% 1.5923566878980893% 1.5923566878980893% 0.3184713375796178%;
}

.PoPhysicalOrthoticFootOrthotic2Side1 {
    grid-row-start: 1;
    grid-row-end: 43;
    grid-column-start: 1;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Side3 {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 4;
    grid-column-end: 5;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Side4 {
    grid-row-start: 4;
    grid-row-end: 9;
    grid-column-start: 6;
    grid-column-end: 54;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Side8 {
    grid-row-start: 10;
    grid-row-end: 14;
    grid-column-start: 9;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Side9 {
    grid-row-start: 13;
    grid-row-end: 26;
    grid-column-start: 12;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Side21 {
    grid-row-start: 27;
    grid-row-end: 42;
    grid-column-start: 13;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Side33 {
    grid-row-start: 26;
    grid-row-end: 31;
    grid-column-start: 9;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 44;
    grid-row-end: 47;
    grid-column-start: 55;
    grid-column-end: 58;
    width: 100%;
    height: 100%;
}
