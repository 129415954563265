.card-patient-panel {
  .Img-container {
    position: relative;
    .label-tag-image {
      position: absolute;
      right: -10px;
      top: -1px;
    }
  }
}
