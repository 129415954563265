
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 0.5449591280653951% 3.8147138964577656% 2.4523160762942777% 1.0899182561307903% 1.0899182561307903% 1.3623978201634876% 1.3623978201634876% 1.6348773841961852% 1.6348773841961852% 1.3623978201634876% 2.4523160762942777% 0.5449591280653951% 0.5449591280653951% 0.2724795640326976% 0.2724795640326976% 1.3623978201634876% 0.2724795640326976% 0.8174386920980926% 0.5449591280653951% 2.4523160762942777% 0.5449591280653951% 1.6348773841961852% 10.354223433242506% 2.1798365122615806% 1.3623978201634876% 0.5449591280653951% 0.8174386920980926% 0.5449591280653951% 0.2724795640326976% 1.0899182561307903% 3.2697547683923704% 1.0899182561307903% 1.9073569482288828% 1.6348773841961852% 2.997275204359673% 0.8174386920980926% 2.1798365122615806% 0.8174386920980926% 2.4523160762942777% 0.5449591280653951% 1.6348773841961852% 1.3623978201634876% 2.7247956403269753% 2.4523160762942777% 1.3623978201634876% 0.2724795640326976% 2.1798365122615806% 0.2724795640326976% 1.0899182561307903% 2.4523160762942777% 5.449591280653951% 1.6348773841961852% 2.1798365122615806% 1.3623978201634876% 2.1798365122615806% 8.446866485013624% 0.0%;
    grid-template-columns: 0.0% 0.22346368715083798% 0.8938547486033519% 0.33519553072625696% 0.44692737430167595% 0.33519553072625696% 0.11173184357541899% 0.11173184357541899% 0.11173184357541899% 1.675977653631285% 0.11173184357541899% 2.122905027932961% 0.22346368715083798% 0.22346368715083798% 1.1173184357541899% 0.6703910614525139% 3.5754189944134076% 1.5642458100558658% 1.3407821229050279% 0.11173184357541899% 1.229050279329609% 0.11173184357541899% 0.6703910614525139% 0.11173184357541899% 0.5586592178770949% 0.44692737430167595% 0.7821229050279329% 0.7821229050279329% 1.1173184357541899% 2.905027932960894% 0.8938547486033519% 0.11173184357541899% 1.3407821229050279% 0.44692737430167595% 0.22346368715083798% 0.7821229050279329% 0.22346368715083798% 0.44692737430167595% 0.5586592178770949% 0.11173184357541899% 0.5586592178770949% 5.698324022346369% 0.5586592178770949% 2.2346368715083798% 0.5586592178770949% 1.899441340782123% 0.5586592178770949% 6.927374301675978% 0.5586592178770949% 1.005586592178771% 0.33519553072625696% 0.7821229050279329% 2.011173184357542% 0.33519553072625696% 3.5754189944134076% 7.709497206703911% 1.005586592178771% 5.251396648044692% 0.44692737430167595% 19.441340782122904% 3.2402234636871508% 1.7877094972067038% 1.3407821229050279% 0.11173184357541899% 0.6703910614525139% 1.1173184357541899% 0.22346368715083798% 0.7821229050279329% 0.11173184357541899% 0.0%;
}

.PtProgressnoteOpdCardioNeuro4 {
    grid-row-start: 3;
    grid-row-end: 50;
    grid-column-start: 4;
    grid-column-end: 68;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro5 {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 8;
    grid-column-end: 21;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro6 {
    grid-row-start: 4;
    grid-row-end: 7;
    grid-column-start: 21;
    grid-column-end: 64;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro8 {
    grid-row-start: 9;
    grid-row-end: 12;
    grid-column-start: 21;
    grid-column-end: 64;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro9 {
    grid-row-start: 10;
    grid-row-end: 11;
    grid-column-start: 7;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro10 {
    grid-row-start: 13;
    grid-row-end: 21;
    grid-column-start: 42;
    grid-column-end: 64;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro11 {
    grid-row-start: 15;
    grid-row-end: 20;
    grid-column-start: 20;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro12 {
    grid-row-start: 15;
    grid-row-end: 18;
    grid-column-start: 9;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro13 {
    grid-row-start: 15;
    grid-row-end: 20;
    grid-column-start: 33;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro14 {
    grid-row-start: 15;
    grid-row-end: 18;
    grid-column-start: 36;
    grid-column-end: 40;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro15 {
    grid-row-start: 15;
    grid-row-end: 18;
    grid-column-start: 23;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro16 {
    grid-row-start: 22;
    grid-row-end: 25;
    grid-column-start: 21;
    grid-column-end: 64;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro17 {
    grid-row-start: 23;
    grid-row-end: 24;
    grid-column-start: 8;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.Ct2DischargeToday {
    grid-row-start: 26;
    grid-row-end: 32;
    grid-column-start: 21;
    grid-column-end: 40;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro21 {
    grid-row-start: 27;
    grid-row-end: 30;
    grid-column-start: 8;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro22 {
    grid-row-start: 33;
    grid-row-end: 36;
    grid-column-start: 21;
    grid-column-end: 56;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro23 {
    grid-row-start: 34;
    grid-row-end: 35;
    grid-column-start: 8;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro24 {
    grid-row-start: 37;
    grid-row-end: 40;
    grid-column-start: 20;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro25 {
    grid-row-start: 37;
    grid-row-end: 39;
    grid-column-start: 25;
    grid-column-end: 56;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro27 {
    grid-row-start: 41;
    grid-row-end: 44;
    grid-column-start: 20;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro28 {
    grid-row-start: 41;
    grid-row-end: 43;
    grid-column-start: 25;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro29 {
    grid-row-start: 45;
    grid-row-end: 52;
    grid-column-start: 6;
    grid-column-end: 52;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro30 {
    grid-row-start: 46;
    grid-row-end: 51;
    grid-column-start: 18;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro31 {
    grid-row-start: 46;
    grid-row-end: 51;
    grid-column-start: 42;
    grid-column-end: 47;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro32 {
    grid-row-start: 46;
    grid-row-end: 51;
    grid-column-start: 48;
    grid-column-end: 53;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro33 {
    grid-row-start: 47;
    grid-row-end: 49;
    grid-column-start: 11;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro34 {
    grid-row-start: 47;
    grid-row-end: 49;
    grid-column-start: 29;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro35 {
    grid-row-start: 47;
    grid-row-end: 49;
    grid-column-start: 36;
    grid-column-end: 38;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro43 {
    grid-row-start: 48;
    grid-row-end: 49;
    grid-column-start: 46;
    grid-column-end: 47;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro36 {
    grid-row-start: 45;
    grid-row-end: 52;
    grid-column-start: 53;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro37 {
    grid-row-start: 46;
    grid-row-end: 51;
    grid-column-start: 56;
    grid-column-end: 57;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro38 {
    grid-row-start: 46;
    grid-row-end: 51;
    grid-column-start: 60;
    grid-column-end: 65;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro39 {
    grid-row-start: 47;
    grid-row-end: 49;
    grid-column-start: 54;
    grid-column-end: 54;
    width: 100%;
    height: 100%;
}
.PtProgressnoteOpdCardioNeuro40 {
    grid-row-start: 47;
    grid-row-end: 49;
    grid-column-start: 58;
    grid-column-end: 60;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 51;
    grid-row-end: 53;
    grid-column-start: 61;
    grid-column-end: 67;
    width: 100%;
    height: 100%;
}
