
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 0.5291005291005291% 6.878306878306878% 2.1164021164021163% 1.0582010582010581% 0.5291005291005291% 1.5873015873015872% 0.5291005291005291% 3.1746031746031744% 4.232804232804233% 2.6455026455026456% 0.5291005291005291% 1.0582010582010581% 1.0582010582010581% 1.0582010582010581% 0.5291005291005291% 2.6455026455026456% 3.7037037037037037% 3.1746031746031744% 1.0582010582010581% 1.0582010582010581% 1.0582010582010581% 0.5291005291005291% 1.0582010582010581% 1.5873015873015872% 4.761904761904762% 0.5291005291005291% 1.5873015873015872% 0.5291005291005291% 0.5291005291005291% 0.5291005291005291% 1.5873015873015872% 0.5291005291005291% 0.5291005291005291% 0.5291005291005291% 1.0582010582010581% 1.0582010582010581% 2.6455026455026456% 3.1746031746031744% 1.0582010582010581% 1.0582010582010581% 1.0582010582010581% 1.0582010582010581% 2.1164021164021163% 3.1746031746031744% 2.6455026455026456% 0.5291005291005291% 0.5291005291005291% 1.5873015873015872% 0.5291005291005291% 0.5291005291005291% 2.6455026455026456% 5.8201058201058204% 1.0582010582010581% 1.0582010582010581% 0.5291005291005291% 0.5291005291005291% 2.6455026455026456% 0.5291005291005291% 0.5291005291005291% 1.5873015873015872% 0.5291005291005291% 3.7037037037037037% 0.0%;
    grid-template-columns: 0.0% 0.10869565217391304% 0.21739130434782608% 1.7391304347826086% 0.10869565217391304% 5.217391304347826% 0.9782608695652174% 0.43478260869565216% 0.5434782608695652% 1.6304347826086956% 1.8478260869565217% 1.0869565217391304% 0.10869565217391304% 0.21739130434782608% 0.7608695652173914% 0.21739130434782608% 0.6521739130434783% 0.7608695652173914% 1.0869565217391304% 1.0869565217391304% 0.8695652173913043% 3.0434782608695654% 0.10869565217391304% 0.43478260869565216% 0.5434782608695652% 0.8695652173913043% 0.43478260869565216% 0.21739130434782608% 0.32608695652173914% 0.5434782608695652% 0.32608695652173914% 0.5434782608695652% 0.43478260869565216% 2.0652173913043477% 0.21739130434782608% 1.1956521739130435% 0.10869565217391304% 0.9782608695652174% 0.32608695652173914% 0.10869565217391304% 0.21739130434782608% 1.5217391304347827% 0.6521739130434783% 1.7391304347826086% 2.0652173913043477% 0.6521739130434783% 0.6521739130434783% 0.21739130434782608% 0.5434782608695652% 0.8695652173913043% 0.32608695652173914% 0.8695652173913043% 1.1956521739130435% 2.5% 0.21739130434782608% 1.4130434782608696% 0.9782608695652174% 0.10869565217391304% 0.43478260869565216% 0.8695652173913043% 0.10869565217391304% 0.9782608695652174% 0.43478260869565216% 0.6521739130434783% 0.43478260869565216% 0.5434782608695652% 0.5434782608695652% 0.32608695652173914% 0.32608695652173914% 1.0869565217391304% 2.717391304347826% 0.32608695652173914% 0.10869565217391304% 0.5434782608695652% 0.21739130434782608% 1.8478260869565217% 1.6304347826086956% 1.1956521739130435% 1.9565217391304348% 5.543478260869565% 0.9782608695652174% 0.7608695652173914% 0.5434782608695652% 0.10869565217391304% 0.6521739130434783% 0.10869565217391304% 4.456521739130435% 0.7608695652173914% 12.282608695652174% 1.7391304347826086% 1.5217391304347827% 0.6521739130434783% 1.5217391304347827% 1.6304347826086956% 0.21739130434782608%;
}

.PtPhysicalOrthoOpdTop2 {
    grid-row-start: 3;
    grid-row-end: 64;
    grid-column-start: 4;
    grid-column-end: 95;
    width: 100%;
    height: 100%;
}
.Ct5Observation {
    grid-row-start: 4;
    grid-row-end: 10;
    grid-column-start: 12;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop6 {
    grid-row-start: 4;
    grid-row-end: 10;
    grid-column-start: 34;
    grid-column-end: 55;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop7 {
    grid-row-start: 5;
    grid-row-end: 8;
    grid-column-start: 6;
    grid-column-end: 7;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop8 {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 12;
    grid-column-end: 56;
    width: 100%;
    height: 100%;
}
.Ct10GeneralAppearance {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 57;
    grid-column-end: 76;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop12 {
    grid-row-start: 12;
    grid-row-end: 16;
    grid-column-start: 5;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.Ct12Consciousness {
    grid-row-start: 19;
    grid-row-end: 26;
    grid-column-start: 12;
    grid-column-end: 27;
    width: 100%;
    height: 100%;
}
.Ct15Communication {
    grid-row-start: 19;
    grid-row-end: 26;
    grid-column-start: 46;
    grid-column-end: 61;
    width: 100%;
    height: 100%;
}
.Ct15CoOperation {
    grid-row-start: 19;
    grid-row-end: 26;
    grid-column-start: 78;
    grid-column-end: 86;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop22 {
    grid-row-start: 20;
    grid-row-end: 23;
    grid-column-start: 5;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop23 {
    grid-row-start: 20;
    grid-row-end: 23;
    grid-column-start: 35;
    grid-column-end: 45;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop24 {
    grid-row-start: 20;
    grid-row-end: 25;
    grid-column-start: 71;
    grid-column-end: 78;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop25 {
    grid-row-start: 29;
    grid-row-end: 37;
    grid-column-start: 12;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
}
.Ct3BedMobilityTestRolling {
    grid-row-start: 27;
    grid-row-end: 38;
    grid-column-start: 36;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop29 {
    grid-row-start: 27;
    grid-row-end: 38;
    grid-column-start: 54;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop32 {
    grid-row-start: 29;
    grid-row-end: 35;
    grid-column-start: 6;
    grid-column-end: 12;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop33 {
    grid-row-start: 30;
    grid-row-end: 34;
    grid-column-start: 24;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop34 {
    grid-row-start: 29;
    grid-row-end: 34;
    grid-column-start: 17;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop35 {
    grid-row-start: 39;
    grid-row-end: 45;
    grid-column-start: 36;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop38 {
    grid-row-start: 39;
    grid-row-end: 45;
    grid-column-start: 54;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop40 {
    grid-row-start: 40;
    grid-row-end: 44;
    grid-column-start: 23;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop41 {
    grid-row-start: 46;
    grid-row-end: 53;
    grid-column-start: 36;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop44 {
    grid-row-start: 46;
    grid-row-end: 53;
    grid-column-start: 54;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop46 {
    grid-row-start: 48;
    grid-row-end: 51;
    grid-column-start: 23;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop47 {
    grid-row-start: 54;
    grid-row-end: 62;
    grid-column-start: 22;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop48 {
    grid-row-start: 56;
    grid-row-end: 62;
    grid-column-start: 12;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop49 {
    grid-row-start: 54;
    grid-row-end: 62;
    grid-column-start: 48;
    grid-column-end: 63;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop50 {
    grid-row-start: 56;
    grid-row-end: 60;
    grid-column-start: 6;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop51 {
    grid-row-start: 56;
    grid-row-end: 62;
    grid-column-start: 42;
    grid-column-end: 43;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop52 {
    grid-row-start: 54;
    grid-row-end: 62;
    grid-column-start: 77;
    grid-column-end: 81;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop53 {
    grid-row-start: 56;
    grid-row-end: 59;
    grid-column-start: 16;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop54 {
    grid-row-start: 56;
    grid-row-end: 62;
    grid-column-start: 65;
    grid-column-end: 68;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop55 {
    grid-row-start: 54;
    grid-row-end: 62;
    grid-column-start: 89;
    grid-column-end: 90;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop56 {
    grid-row-start: 56;
    grid-row-end: 60;
    grid-column-start: 44;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop57 {
    grid-row-start: 56;
    grid-row-end: 62;
    grid-column-start: 82;
    grid-column-end: 84;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop58 {
    grid-row-start: 56;
    grid-row-end: 60;
    grid-column-start: 70;
    grid-column-end: 73;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop59 {
    grid-row-start: 56;
    grid-row-end: 62;
    grid-column-start: 91;
    grid-column-end: 92;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop60 {
    grid-row-start: 56;
    grid-row-end: 59;
    grid-column-start: 85;
    grid-column-end: 88;
    width: 100%;
    height: 100%;
}
.PtPhysicalOrthoOpdTop61 {
    grid-row-start: 56;
    grid-row-end: 59;
    grid-column-start: 93;
    grid-column-end: 94;
    width: 100%;
    height: 100%;
}
