/* TimeDoctor.css */

:root{
  --primary-err-color: #FF2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #F2F2F2;
  --disable-bg-color: #606060;
} 

html[data-theme="penta"]{
  --primary-theme-color: rgba(93,188,210);
  --primary-theme-color-half: rgba(93,188,210, 0.5);
  --primary-theme-color-08: rgba(93,188,210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93,188,210, 0.2);
  --light-bg-color: rgba(93,188,210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0,42,132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
}

html[data-theme="penguin"]{
  --primary-theme-color: rgba(35,153,229);
  --primary-theme-color-half: rgba(35,153,229, 0.5);
  --primary-theme-color-08: rgba(35,153,229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35,153,229, 0.2);
  --light-bg-color: rgba(35,153,229, 0.3);
  --dark-bg-color: rgba(35,153,229);
  --dark-bg-color-07: rgba(35,153,229, 0.7);
  --chat-bubble-color: rgba(35,153,229, 0.2);
  --chat-bubble-color-selected: rgba(35,153,229, 0.4);
  --primary-err-color: #FF4D4D;
  --std-border: 0;
}

.DoctorSchedule .service_slot{
  cursor: default;
  border-right: 0.5px solid var(--primary-theme-color);
}

.DoctorSchedule .service_slot.disabled{
  background-color: rgba(176,176,176, 0.5);
  cursor: default;
}

.DoctorSchedule .service_slot.border{
  border-left: 0.5px solid var(--primary-theme-color);
}

.DoctorSchedule .service_slot .slotTime{
  flex: 1; 
  padding-left: 2%;
  padding-right: 5px;
}

.DoctorSchedule .service_slot .slotTime.bottomBorder{
  border-bottom: solid var(--primary-theme-color) 1px
}

.DoctorSchedule .service_slot .slotName.bottomBorder{
  border-bottom: solid var(--primary-theme-color) 1px
}

.DoctorSchedule .service_slot .slotName{
  flex: 4; 
  padding-right: 2%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DoctorSchedule .service_slot.active:not(.disabled){
  cursor: pointer;
}

.DoctorSchedule .service_slot.active:hover:not(.disabled){
  background-color: var(--light-bg-color);
}

.DoctorSchedule .header{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  padding-bottom: 0;
}

.DoctorSchedule .header .button{
  background-color: var(--primary-theme-color-half);
}

.DoctorSchedule .header .button:hover{
  background-color: var(--primary-theme-color-08);
}

.DoctorSchedule .header .dateRange{
  margin-left: 10px;
  margin-right: 10px;
}

.DoctorSchedule .content{
  cursor: default;
  padding-left: 1%;
  padding-right: 1%;
}

.DoctorSchedule .calendarContent{
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: solid var(--primary-theme-color) 1px;
}

.DoctorSchedule .calendarContent::-webkit-scrollbar {
  /* width: 0px; */
  /* background: transparent;  */
  background: var(--hover-bg);
}

.DoctorSchedule .calendarContent::-webkit-scrollbar-thumb {
  background: var(--primary-theme-color-08);
}

.DoctorSchedule .calendar{
  display: flex;
}

.DoctorSchedule .calendar .dateHeader{
  text-align: center;
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  position: sticky;
  top: 0;
}

.DoctorSchedule .calendar .weekDisplay{
  flex: 1;
  align-items: flex-start;
}

.TimeDoctorEdit .dateHeader{
  display: flex; 
  justify-content: center; 
  align-items: center;
  padding: 1%;
}

.TimeDoctorEdit .content{
  padding-left: 1%;
  padding-right: 1%;
}

.TimeDoctorEdit .calendarContent{
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* border-bottom: solid var(--primary-theme-color) 1px; */
}

.TimeDoctorEdit .calendarContent::-webkit-scrollbar {
  /* width: 0px; */
  /* background: transparent;  */
  background: var(--hover-bg);
}

.TimeDoctorEdit .calendarContent::-webkit-scrollbar-thumb {
  background: var(--primary-theme-color-08);
}

.TimeDoctorEdit .service_slot{
  cursor: default;
}

.TimeDoctorEdit .service_slot .slotTime,
.TimeDoctorEdit .service_slot .slotName{
  text-align: center;
  flex: 1;
  background-color: var(--hover-bg);
  border-right: solid white 2px;
}

.TimeDoctorEdit .service_slot .slotTime.borderBottom,
.TimeDoctorEdit .service_slot .slotName.borderBottom,
.TimeDoctorEdit .service_slot .divisionName.borderBottom{
  border-bottom: solid var(--primary-theme-color) 1px;
}

.TimeDoctorEdit .service_slot .divisionName{
  flex: 1; 
  text-align: center;
  border-right: solid var(--hover-bg) 2px;
}

.TimeDoctorEdit .service_slot .divisionName .active{
  background-color: var(--primary-theme-color-half, yellow);
}

.TimeDoctorEdit .service_slot .divisionName .textContent:hover:not(.active){
  background-color: var(--hover-bg);
}