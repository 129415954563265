
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.6507592190889371% 2.386117136659436% 1.5184381778741864% 1.5184381778741864% 1.0845986984815619% 2.1691973969631237% 1.3015184381778742% 1.5184381778741864% 1.0845986984815619% 1.9522776572668112% 1.5184381778741864% 1.5184381778741864% 1.0845986984815619% 2.1691973969631237% 1.5184381778741864% 1.5184381778741864% 0.8676789587852495% 2.386117136659436% 0.43383947939262474% 0.8676789587852495% 0.21691973969631237% 0.43383947939262474% 0.21691973969631237% 0.6507592190889371% 0.21691973969631237% 0.8676789587852495% 0.6507592190889371% 1.9522776572668112% 1.0845986984815619% 0.43383947939262474% 0.43383947939262474% 1.0845986984815619% 0.21691973969631237% 1.0845986984815619% 6.507592190889371% 3.036876355748373% 0.43383947939262474% 0.21691973969631237% 1.3015184381778742% 0.43383947939262474% 0.8676789587852495% 0.6507592190889371% 0.6507592190889371% 0.6507592190889371% 1.5184381778741864% 1.0845986984815619% 0.21691973969631237% 2.1691973969631237% 1.0845986984815619% 1.5184381778741864% 1.5184381778741864% 2.6030368763557483% 0.8676789587852495% 1.735357917570499% 0.6507592190889371% 1.9522776572668112% 0.8676789587852495% 1.3015184381778742% 1.0845986984815619% 6.073752711496746% 2.386117136659436% 0.21691973969631237% 0.6507592190889371% 0.43383947939262474% 1.3015184381778742% 0.21691973969631237% 0.21691973969631237% 0.8676789587852495% 2.386117136659436% 1.3015184381778742% 1.735357917570499% 1.9522776572668112% 1.0845986984815619% 1.9522776572668112% 3.6876355748373104%;
    grid-template-columns: 0.4166666666666667% 1.25% 0.625% 0.10416666666666667% 0.10416666666666667% 1.9791666666666667% 1.4583333333333333% 1.0416666666666667% 0.10416666666666667% 0.4166666666666667% 1.0416666666666667% 0.20833333333333334% 1.4583333333333333% 0.10416666666666667% 6.979166666666667% 0.4166666666666667% 0.3125% 0.3125% 0.5208333333333334% 0.20833333333333334% 0.7291666666666666% 0.7291666666666666% 1.0416666666666667% 0.20833333333333334% 1.25% 3.6458333333333335% 1.4583333333333333% 0.20833333333333334% 1.6666666666666667% 0.5208333333333334% 0.10416666666666667% 0.4166666666666667% 0.7291666666666666% 4.6875% 1.0416666666666667% 0.10416666666666667% 0.4166666666666667% 1.1458333333333333% 0.8333333333333334% 0.625% 0.625% 0.10416666666666667% 1.3541666666666667% 4.375% 1.0416666666666667% 0.5208333333333334% 1.1458333333333333% 0.10416666666666667% 0.4166666666666667% 0.7291666666666666% 0.7291666666666666% 1.25% 0.5208333333333334% 6.458333333333333% 4.6875% 1.6666666666666667% 4.479166666666667% 0.7291666666666666% 19.479166666666668% 1.1458333333333333% 0.8333333333333334% 1.3541666666666667% 0.10416666666666667% 0.7291666666666666% 1.0416666666666667% 0.4166666666666667% 0.4166666666666667% 0.7291666666666666% 0.7291666666666666% 0.9375% 0.5208333333333334%;
}

.PoRehabilitationTreatmentOrthoticFittingCheckout1 {
    grid-row-start: 2;
    grid-row-end: 71;
    grid-column-start: 2;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout2 {
    grid-row-start: 3;
    grid-row-end: 6;
    grid-column-start: 17;
    grid-column-end: 68;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout3 {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 4;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout4 {
    grid-row-start: 7;
    grid-row-end: 10;
    grid-column-start: 17;
    grid-column-end: 68;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout5 {
    grid-row-start: 8;
    grid-row-end: 9;
    grid-column-start: 5;
    grid-column-end: 16;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout6 {
    grid-row-start: 11;
    grid-row-end: 14;
    grid-column-start: 17;
    grid-column-end: 68;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout7 {
    grid-row-start: 12;
    grid-row-end: 13;
    grid-column-start: 5;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout8 {
    grid-row-start: 15;
    grid-row-end: 18;
    grid-column-start: 17;
    grid-column-end: 68;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout9 {
    grid-row-start: 16;
    grid-row-end: 17;
    grid-column-start: 6;
    grid-column-end: 16;
    width: 100%;
    height: 100%;
}
.Ct4PainScore {
    grid-row-start: 20;
    grid-row-end: 28;
    grid-column-start: 17;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout13 {
    grid-row-start: 19;
    grid-row-end: 27;
    grid-column-start: 43;
    grid-column-end: 68;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout14 {
    grid-row-start: 22;
    grid-row-end: 25;
    grid-column-start: 6;
    grid-column-end: 12;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout15 {
    grid-row-start: 21;
    grid-row-end: 25;
    grid-column-start: 37;
    grid-column-end: 40;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout16 {
    grid-row-start: 29;
    grid-row-end: 36;
    grid-column-start: 17;
    grid-column-end: 61;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout17 {
    grid-row-start: 31;
    grid-row-end: 33;
    grid-column-start: 6;
    grid-column-end: 7;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout18 {
    grid-row-start: 30;
    grid-row-end: 35;
    grid-column-start: 62;
    grid-column-end: 63;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout19 {
    grid-row-start: 30;
    grid-row-end: 33;
    grid-column-start: 65;
    grid-column-end: 67;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout20 {
    grid-row-start: 37;
    grid-row-end: 42;
    grid-column-start: 19;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout21 {
    grid-row-start: 38;
    grid-row-end: 40;
    grid-column-start: 6;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout22 {
    grid-row-start: 37;
    grid-row-end: 41;
    grid-column-start: 23;
    grid-column-end: 46;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout23 {
    grid-row-start: 43;
    grid-row-end: 47;
    grid-column-start: 26;
    grid-column-end: 48;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout24 {
    grid-row-start: 44;
    grid-row-end: 47;
    grid-column-start: 19;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout25 {
    grid-row-start: 44;
    grid-row-end: 46;
    grid-column-start: 23;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout26 {
    grid-row-start: 49;
    grid-row-end: 52;
    grid-column-start: 18;
    grid-column-end: 55;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout27 {
    grid-row-start: 50;
    grid-row-end: 51;
    grid-column-start: 4;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout28 {
    grid-row-start: 53;
    grid-row-end: 56;
    grid-column-start: 19;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout29 {
    grid-row-start: 53;
    grid-row-end: 55;
    grid-column-start: 23;
    grid-column-end: 55;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout30 {
    grid-row-start: 57;
    grid-row-end: 60;
    grid-column-start: 19;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout31 {
    grid-row-start: 57;
    grid-row-end: 59;
    grid-column-start: 23;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout32 {
    grid-row-start: 61;
    grid-row-end: 70;
    grid-column-start: 3;
    grid-column-end: 48;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout33 {
    grid-row-start: 63;
    grid-row-end: 69;
    grid-column-start: 14;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout35 {
    grid-row-start: 63;
    grid-row-end: 69;
    grid-column-start: 32;
    grid-column-end: 38;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout37 {
    grid-row-start: 63;
    grid-row-end: 69;
    grid-column-start: 42;
    grid-column-end: 47;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout39 {
    grid-row-start: 65;
    grid-row-end: 67;
    grid-column-start: 6;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout40 {
    grid-row-start: 65;
    grid-row-end: 66;
    grid-column-start: 23;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout41 {
    grid-row-start: 65;
    grid-row-end: 66;
    grid-column-start: 28;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout42 {
    grid-row-start: 65;
    grid-row-end: 66;
    grid-column-start: 39;
    grid-column-end: 41;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout43 {
    grid-row-start: 61;
    grid-row-end: 70;
    grid-column-start: 50;
    grid-column-end: 70;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout44 {
    grid-row-start: 62;
    grid-row-end: 69;
    grid-column-start: 54;
    grid-column-end: 56;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout45 {
    grid-row-start: 62;
    grid-row-end: 69;
    grid-column-start: 59;
    grid-column-end: 69;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout46 {
    grid-row-start: 64;
    grid-row-end: 67;
    grid-column-start: 57;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout47 {
    grid-row-start: 65;
    grid-row-end: 66;
    grid-column-start: 51;
    grid-column-end: 53;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 72;
    grid-row-end: 75;
    grid-column-start: 60;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentOrthoticFittingCheckout49 {
    grid-row-start: 73;
    grid-row-end: 74;
    grid-column-start: 63;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
