.QueueForPatient {
	position: relative;

	.marquee {
		overflow: hidden;
	}

	.marquee>* {
		white-space: nowrap;
		margin-left: 60px;
		position: absolute;
		animation: marquee 30s linear 0s infinite;

	}

	@keyframes marquee {
		0% {
			left: 100%;
			transform: translateX(0%);
		}

		100% {
			left: 0%;
			transform: translateX(-100%);
		}
	}
}