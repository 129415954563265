.template-file {
  position: relative;
}

.template-file .image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.template-file .middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.template-file .image.selected{
  opacity: 0.3;
}

.template-file .middle.selected{
  opacity: 1;
}

