#drug-transportation {
    .ui.form .inline.field > .selection.dropdown,
    .ui.form .inline.fields .field > .selection.dropdown {
      font-size: unset;
    }
  }

#drug-transportation {
  .ui.form .inline.field > .ui.label,
  .ui.form .inline.fields .field > .ui.label {
    font-size: 14px;
  }
}
  