
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 2.032520325203252% 1.6260162601626016% 1.2195121951219512% 0.4065040650406504% 0.4065040650406504% 0.4065040650406504% 1.2195121951219512% 0.4065040650406504% 0.4065040650406504% 0.4065040650406504% 1.2195121951219512% 0.4065040650406504% 3.658536585365854% 1.2195121951219512% 0.4065040650406504% 0.4065040650406504% 0.4065040650406504% 0.4065040650406504% 0.8130081300813008% 0.8130081300813008% 0.8130081300813008% 0.8130081300813008% 0.4065040650406504% 3.658536585365854% 0.4065040650406504% 0.8130081300813008% 0.4065040650406504% 0.4065040650406504% 0.4065040650406504% 1.2195121951219512% 0.4065040650406504% 0.4065040650406504% 0.4065040650406504% 1.2195121951219512% 0.4065040650406504% 3.658536585365854% 1.6260162601626016% 2.845528455284553% 0.4065040650406504% 1.6260162601626016% 2.4390243902439024% 2.032520325203252% 0.8130081300813008% 0.8130081300813008% 0.4065040650406504% 0.4065040650406504% 2.032520325203252% 2.4390243902439024% 1.6260162601626016% 0.8130081300813008% 0.4065040650406504% 1.2195121951219512% 0.4065040650406504% 2.4390243902439024% 6.9105691056910565% 1.2195121951219512% 2.032520325203252% 17.479674796747968% 11.382113821138212% 2.032520325203252% 0.0%;
    grid-template-columns: 0.0% 0.8635578583765112% 1.0362694300518134% 2.0725388601036268% 0.17271157167530224% 4.490500863557858% 1.5544041450777202% 0.17271157167530224% 0.690846286701209% 3.2815198618307426% 1.381692573402418% 0.3454231433506045% 1.0362694300518134% 3.2815198618307426% 0.17271157167530224% 1.7271157167530224% 0.17271157167530224% 1.8998272884283247% 2.0725388601036268% 0.17271157167530224% 8.290155440414507% 1.381692573402418% 0.690846286701209% 0.17271157167530224% 1.2089810017271156% 0.8635578583765112% 2.0725388601036268% 2.0725388601036268% 1.2089810017271156% 0.5181347150259067% 1.5544041450777202% 0.17271157167530224% 6.0449050086355784% 2.0725388601036268% 1.2089810017271156% 2.763385146804836% 0.17271157167530224% 4.66321243523316% 0.17271157167530224% 21.243523316062177% 14.853195164075993% 0.0%;
}

.PoPhysicalOrthoticFootOrthotic2Low1 {
    grid-row-start: 1;
    grid-row-end: 62;
    grid-column-start: 1;
    grid-column-end: 42;
    width: 100%;
    height: 100%;
}
.CtCalcaneusPosition {
    grid-row-start: 4;
    grid-row-end: 13;
    grid-column-start: 15;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low6 {
    grid-row-start: 4;
    grid-row-end: 13;
    grid-column-start: 39;
    grid-column-end: 41;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low7 {
    grid-row-start: 6;
    grid-row-end: 9;
    grid-column-start: 5;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low8 {
    grid-row-start: 5;
    grid-row-end: 13;
    grid-column-start: 28;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low9 {
    grid-row-start: 5;
    grid-row-end: 13;
    grid-column-start: 34;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low10 {
    grid-row-start: 7;
    grid-row-end: 10;
    grid-column-start: 30;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low11 {
    grid-row-start: 5;
    grid-row-end: 11;
    grid-column-start: 36;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.CtForefootPosition {
    grid-row-start: 15;
    grid-row-end: 24;
    grid-column-start: 15;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low15 {
    grid-row-start: 15;
    grid-row-end: 24;
    grid-column-start: 39;
    grid-column-end: 41;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low16 {
    grid-row-start: 17;
    grid-row-end: 22;
    grid-column-start: 6;
    grid-column-end: 12;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low17 {
    grid-row-start: 16;
    grid-row-end: 24;
    grid-column-start: 28;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low18 {
    grid-row-start: 16;
    grid-row-end: 24;
    grid-column-start: 34;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low19 {
    grid-row-start: 16;
    grid-row-end: 22;
    grid-column-start: 30;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low20 {
    grid-row-start: 16;
    grid-row-end: 23;
    grid-column-start: 36;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.CtArchDeformity {
    grid-row-start: 27;
    grid-row-end: 36;
    grid-column-start: 16;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low24 {
    grid-row-start: 26;
    grid-row-end: 36;
    grid-column-start: 40;
    grid-column-end: 41;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low25 {
    grid-row-start: 29;
    grid-row-end: 34;
    grid-column-start: 6;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low26 {
    grid-row-start: 28;
    grid-row-end: 36;
    grid-column-start: 28;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low27 {
    grid-row-start: 28;
    grid-row-end: 36;
    grid-column-start: 34;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low28 {
    grid-row-start: 28;
    grid-row-end: 33;
    grid-column-start: 30;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low29 {
    grid-row-start: 28;
    grid-row-end: 34;
    grid-column-start: 36;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low30 {
    grid-row-start: 38;
    grid-row-end: 42;
    grid-column-start: 27;
    grid-column-end: 41;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low31 {
    grid-row-start: 39;
    grid-row-end: 40;
    grid-column-start: 6;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low32 {
    grid-row-start: 39;
    grid-row-end: 40;
    grid-column-start: 16;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low33 {
    grid-row-start: 43;
    grid-row-end: 49;
    grid-column-start: 15;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low36 {
    grid-row-start: 44;
    grid-row-end: 47;
    grid-column-start: 6;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low37 {
    grid-row-start: 50;
    grid-row-end: 56;
    grid-column-start: 15;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low40 {
    grid-row-start: 50;
    grid-row-end: 56;
    grid-column-start: 27;
    grid-column-end: 41;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low41 {
    grid-row-start: 51;
    grid-row-end: 54;
    grid-column-start: 5;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low42 {
    grid-row-start: 57;
    grid-row-end: 60;
    grid-column-start: 8;
    grid-column-end: 41;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoticFootOrthotic2Low43 {
    grid-row-start: 58;
    grid-row-end: 59;
    grid-column-start: 6;
    grid-column-end: 7;
    width: 100%;
    height: 100%;
}
