
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 2.1739130434782608% 3.260869565217391% 5.434782608695652% 1.0869565217391304% 4.3478260869565215% 1.0869565217391304% 1.0869565217391304% 1.0869565217391304% 3.260869565217391% 1.0869565217391304% 1.0869565217391304% 4.3478260869565215% 1.0869565217391304% 8.695652173913043% 5.434782608695652% 2.1739130434782608% 2.1739130434782608% 2.1739130434782608% 1.0869565217391304% 2.1739130434782608% 3.260869565217391% 13.043478260869565% 3.260869565217391% 1.0869565217391304% 1.0869565217391304% 6.521739130434782% 2.1739130434782608% 2.1739130434782608% 2.1739130434782608% 5.434782608695652% 1.0869565217391304% 4.3478260869565215% 0.0%;
    grid-template-columns: 0.0% 0.5518763796909493% 0.5518763796909493% 1.3245033112582782% 0.11037527593818984% 6.181015452538631% 0.5518763796909493% 1.9867549668874172% 1.8763796909492274% 0.11037527593818984% 1.1037527593818985% 0.22075055187637968% 0.7726269315673289% 0.8830022075055187% 1.5452538631346577% 1.3245033112582782% 4.415011037527594% 0.5518763796909493% 0.7726269315673289% 4.966887417218543% 1.434878587196468% 1.7660044150110374% 1.3245033112582782% 0.7726269315673289% 1.6556291390728477% 2.097130242825607% 0.5518763796909493% 0.6622516556291391% 1.9867549668874172% 4.746136865342163% 2.4282560706401766% 0.22075055187637968% 0.22075055187637968% 0.6622516556291391% 0.22075055187637968% 1.1037527593818985% 1.2141280353200883% 0.8830022075055187% 0.44150110375275936% 0.11037527593818984% 0.6622516556291391% 1.1037527593818985% 1.2141280353200883% 1.7660044150110374% 0.6622516556291391% 1.3245033112582782% 0.44150110375275936% 0.33112582781456956% 0.33112582781456956% 0.44150110375275936% 0.7726269315673289% 1.2141280353200883% 1.9867549668874172% 5.408388520971302% 0.9933774834437086% 0.7726269315673289% 0.44150110375275936% 0.22075055187637968% 0.6622516556291391% 4.525386313465783% 0.6622516556291391% 5.739514348785872% 0.5518763796909493% 0.9933774834437086% 2.759381898454746% 2.207505518763797% 1.7660044150110374% 1.434878587196468% 0.7726269315673289% 0.5518763796909493% 0.44150110375275936% 0.44150110375275936% 0.22075055187637968% 1.2141280353200883% 0.11037527593818984% 0.5518763796909493% 0.0%;
}

.PoPhysicalOrthoFittingCheckout2Top1 {
    grid-row-start: 1;
    grid-row-end: 34;
    grid-column-start: 1;
    grid-column-end: 77;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top3 {
    grid-row-start: 5;
    grid-row-end: 14;
    grid-column-start: 11;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top5 {
    grid-row-start: 7;
    grid-row-end: 12;
    grid-column-start: 5;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.Ct2GeneralAppearanceDysfunction {
    grid-row-start: 6;
    grid-row-end: 14;
    grid-column-start: 33;
    grid-column-end: 50;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top10 {
    grid-row-start: 6;
    grid-row-end: 14;
    grid-column-start: 51;
    grid-column-end: 63;
    width: 100%;
    height: 100%;
}
.Ct5GeneralAppearance {
    grid-row-start: 6;
    grid-row-end: 14;
    grid-column-start: 64;
    grid-column-end: 74;
    width: 100%;
    height: 100%;
}
.Ct5Consciousness {
    grid-row-start: 16;
    grid-row-end: 23;
    grid-column-start: 11;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.Ct8Communication {
    grid-row-start: 16;
    grid-row-end: 23;
    grid-column-start: 27;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top20 {
    grid-row-start: 17;
    grid-row-end: 20;
    grid-column-start: 6;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.Ct8CoOperation {
    grid-row-start: 16;
    grid-row-end: 23;
    grid-column-start: 52;
    grid-column-end: 60;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top24 {
    grid-row-start: 17;
    grid-row-end: 20;
    grid-column-start: 21;
    grid-column-end: 26;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top25 {
    grid-row-start: 17;
    grid-row-end: 22;
    grid-column-start: 43;
    grid-column-end: 52;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top26 {
    grid-row-start: 24;
    grid-row-end: 31;
    grid-column-start: 17;
    grid-column-end: 22;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top27 {
    grid-row-start: 24;
    grid-row-end: 31;
    grid-column-start: 28;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top28 {
    grid-row-start: 26;
    grid-row-end: 30;
    grid-column-start: 10;
    grid-column-end: 13;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top29 {
    grid-row-start: 26;
    grid-row-end: 29;
    grid-column-start: 6;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top30 {
    grid-row-start: 24;
    grid-row-end: 31;
    grid-column-start: 46;
    grid-column-end: 55;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top31 {
    grid-row-start: 26;
    grid-row-end: 30;
    grid-column-start: 23;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top32 {
    grid-row-start: 26;
    grid-row-end: 28;
    grid-column-start: 13;
    grid-column-end: 16;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top33 {
    grid-row-start: 24;
    grid-row-end: 31;
    grid-column-start: 62;
    grid-column-end: 67;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top34 {
    grid-row-start: 26;
    grid-row-end: 30;
    grid-column-start: 38;
    grid-column-end: 40;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top35 {
    grid-row-start: 26;
    grid-row-end: 29;
    grid-column-start: 24;
    grid-column-end: 27;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top36 {
    grid-row-start: 26;
    grid-row-end: 30;
    grid-column-start: 56;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top37 {
    grid-row-start: 26;
    grid-row-end: 29;
    grid-column-start: 41;
    grid-column-end: 45;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top38 {
    grid-row-start: 26;
    grid-row-end: 30;
    grid-column-start: 68;
    grid-column-end: 69;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top39 {
    grid-row-start: 26;
    grid-row-end: 28;
    grid-column-start: 59;
    grid-column-end: 61;
    width: 100%;
    height: 100%;
}
.PoPhysicalOrthoFittingCheckout2Top40 {
    grid-row-start: 26;
    grid-row-end: 28;
    grid-column-start: 69;
    grid-column-end: 73;
    width: 100%;
    height: 100%;
}
