
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 1.6905071521456436% 0.7802340702210663% 0.13003901170351106% 0.13003901170351106% 0.26007802340702213% 0.39011703511053314% 0.26007802340702213% 0.13003901170351106% 0.13003901170351106% 0.5201560468140443% 0.13003901170351106% 0.7802340702210663% 0.5201560468140443% 7.152145643693108% 1.5604681404421326% 0.9102730819245773% 0.26007802340702213% 0.39011703511053314% 0.26007802340702213% 0.39011703511053314% 0.6501950585175552% 1.4304291287386215% 7.152145643693108% 1.4304291287386215% 0.9102730819245773% 0.13003901170351106% 0.26007802340702213% 0.39011703511053314% 0.26007802340702213% 0.26007802340702213% 0.6501950585175552% 1.3003901170351104% 7.282184655396619% 1.5604681404421326% 0.9102730819245773% 0.26007802340702213% 0.39011703511053314% 0.39011703511053314% 0.13003901170351106% 0.7802340702210663% 1.1703511053315996% 7.152145643693108% 1.5604681404421326% 0.9102730819245773% 0.26007802340702213% 0.5201560468140443% 0.26007802340702213% 0.13003901170351106% 0.7802340702210663% 1.3003901170351104% 7.152145643693108% 2.080624187256177% 0.13003901170351106% 0.7802340702210663% 0.39011703511053314% 0.13003901170351106% 0.26007802340702213% 0.26007802340702213% 0.13003901170351106% 0.7802340702210663% 0.39011703511053314% 1.5604681404421326% 7.152145643693108% 2.210663198959688% 0.26007802340702213% 0.26007802340702213% 0.9102730819245773% 0.7802340702210663% 1.1703511053315996% 7.152145643693108% 2.600780234070221% 0.9102730819245773% 1.3003901170351104% 0.9102730819245773% 1.4304291287386215% 1.5604681404421326%;
    grid-template-columns: 0.6065857885615251% 1.559792027729636% 5.285961871750433% 2.7729636048526864% 0.6065857885615251% 1.8197573656845754% 0.08665511265164645% 1.3864818024263432% 0.6932409012131716% 1.4731369150779896% 1.9064124783362217% 0.1733102253032929% 0.6932409012131716% 0.1733102253032929% 0.43327556325823224% 1.559792027729636% 0.25996533795493937% 1.8197573656845754% 1.2998266897746966% 1.3864818024263432% 7.712305025996534% 0.5199306759098787% 0.8665511265164645% 55.719237435008665% 3.292894280762565% 1.8197573656845754% 2.166377816291161% 1.0398613518197575% 0.08665511265164645% 0.779896013864818%;
}

.OtPhysicalPediatric31 {
    grid-row-start: 1;
    grid-row-end: 73;
    grid-column-start: 2;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
.CtVestibular {
    grid-row-start: 3;
    grid-row-end: 12;
    grid-column-start: 19;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric35 {
    grid-row-start: 4;
    grid-row-end: 9;
    grid-column-start: 3;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric36 {
    grid-row-start: 5;
    grid-row-end: 10;
    grid-column-start: 8;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric38 {
    grid-row-start: 15;
    grid-row-end: 16;
    grid-column-start: 7;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.CtProprioceptive {
    grid-row-start: 17;
    grid-row-end: 23;
    grid-column-start: 19;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric312 {
    grid-row-start: 18;
    grid-row-end: 22;
    grid-column-start: 8;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric313 {
    grid-row-start: 24;
    grid-row-end: 25;
    grid-column-start: 7;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.CtTactile {
    grid-row-start: 26;
    grid-row-end: 33;
    grid-column-start: 19;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric317 {
    grid-row-start: 28;
    grid-row-end: 32;
    grid-column-start: 8;
    grid-column-end: 17;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric318 {
    grid-row-start: 34;
    grid-row-end: 35;
    grid-column-start: 7;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.CtVisual {
    grid-row-start: 36;
    grid-row-end: 42;
    grid-column-start: 19;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric322 {
    grid-row-start: 37;
    grid-row-end: 40;
    grid-column-start: 8;
    grid-column-end: 16;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric323 {
    grid-row-start: 43;
    grid-row-end: 44;
    grid-column-start: 7;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.CtAuditory {
    grid-row-start: 45;
    grid-row-end: 51;
    grid-column-start: 19;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric327 {
    grid-row-start: 46;
    grid-row-end: 49;
    grid-column-start: 8;
    grid-column-end: 17;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric328 {
    grid-row-start: 52;
    grid-row-end: 53;
    grid-column-start: 7;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric329 {
    grid-row-start: 55;
    grid-row-end: 57;
    grid-column-start: 8;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.CtGustatory {
    grid-row-start: 54;
    grid-row-end: 62;
    grid-column-start: 19;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric334 {
    grid-row-start: 64;
    grid-row-end: 65;
    grid-column-start: 7;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric335 {
    grid-row-start: 67;
    grid-row-end: 70;
    grid-column-start: 7;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric336 {
    grid-row-start: 67;
    grid-row-end: 69;
    grid-column-start: 3;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric337 {
    grid-row-start: 67;
    grid-row-end: 69;
    grid-column-start: 10;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric338 {
    grid-row-start: 71;
    grid-row-end: 72;
    grid-column-start: 7;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 74;
    grid-row-end: 77;
    grid-column-start: 25;
    grid-column-end: 29;
    width: 100%;
    height: 100%;
}
