
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 2.9661016949152543% 1.9067796610169492% 1.0593220338983051% 0.847457627118644% 0.847457627118644% 1.0593220338983051% 1.271186440677966% 1.4830508474576272% 0.847457627118644% 2.330508474576271% 1.271186440677966% 1.4830508474576272% 0.847457627118644% 2.542372881355932% 1.4830508474576272% 1.271186440677966% 1.0593220338983051% 2.330508474576271% 0.635593220338983% 0.847457627118644% 0.211864406779661% 0.423728813559322% 0.635593220338983% 0.211864406779661% 0.847457627118644% 0.423728813559322% 2.542372881355932% 1.271186440677966% 1.4830508474576272% 2.1186440677966103% 0.847457627118644% 1.271186440677966% 0.635593220338983% 2.330508474576271% 2.9661016949152543% 0.211864406779661% 0.211864406779661% 1.271186440677966% 0.423728813559322% 0.635593220338983% 1.4830508474576272% 0.423728813559322% 0.635593220338983% 1.4830508474576272% 0.847457627118644% 0.211864406779661% 2.7542372881355934% 1.0593220338983051% 1.4830508474576272% 1.271186440677966% 1.271186440677966% 0.635593220338983% 1.4830508474576272% 0.847457627118644% 1.9067796610169492% 0.423728813559322% 1.271186440677966% 1.0593220338983051% 4.02542372881356% 2.1186440677966103% 0.211864406779661% 0.847457627118644% 0.211864406779661% 1.271186440677966% 0.211864406779661% 1.0593220338983051% 2.1186440677966103% 3.8135593220338984% 1.271186440677966% 1.694915254237288% 1.271186440677966% 1.694915254237288% 8.26271186440678%;
    grid-template-columns: 0.547645125958379% 1.7524644030668126% 0.10952902519167579% 0.10952902519167579% 0.10952902519167579% 0.547645125958379% 1.4238773274917853% 2.190580503833516% 0.32858707557502737% 0.10952902519167579% 0.9857612267250822% 0.6571741511500547% 0.32858707557502737% 1.095290251916758% 0.21905805038335158% 3.723986856516977% 3.6144578313253013% 0.10952902519167579% 0.21905805038335158% 0.32858707557502737% 0.43811610076670315% 0.32858707557502737% 0.21905805038335158% 0.43811610076670315% 0.6571741511500547% 0.10952902519167579% 1.095290251916758% 0.43811610076670315% 0.8762322015334063% 4.16210295728368% 1.095290251916758% 0.43811610076670315% 1.8619934282584885% 0.43811610076670315% 0.21905805038335158% 0.547645125958379% 5.257393209200438% 0.547645125958379% 0.43811610076670315% 0.547645125958379% 1.2048192771084338% 0.21905805038335158% 1.095290251916758% 0.32858707557502737% 0.43811610076670315% 0.7667031763417306% 5.038335158817087% 0.9857612267250822% 0.547645125958379% 0.547645125958379% 0.6571741511500547% 0.43811610076670315% 0.7667031763417306% 0.10952902519167579% 1.8619934282584885% 0.547645125958379% 5.9145673603504925% 5.147864184008762% 1.7524644030668126% 4.381161007667032% 0.7667031763417306% 18.838992332968235% 1.095290251916758% 0.8762322015334063% 1.3143483023001095% 0.21905805038335158% 0.7667031763417306% 0.8762322015334063% 0.547645125958379% 0.43811610076670315% 1.2048192771084338% 0.6571741511500547% 0.32858707557502737% 0.21905805038335158% 0.43811610076670315%;
}

.PtRehabilitationTreatment1 {
    grid-row-start: 1;
    grid-row-end: 65;
    grid-column-start: 2;
    grid-column-end: 75;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment2 {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 6;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment3 {
    grid-row-start: 3;
    grid-row-end: 6;
    grid-column-start: 18;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment4 {
    grid-row-start: 5;
    grid-row-end: 7;
    grid-column-start: 5;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment5 {
    grid-row-start: 8;
    grid-row-end: 11;
    grid-column-start: 18;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment6 {
    grid-row-start: 9;
    grid-row-end: 10;
    grid-column-start: 5;
    grid-column-end: 17;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment7 {
    grid-row-start: 12;
    grid-row-end: 19;
    grid-column-start: 18;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment8 {
    grid-row-start: 13;
    grid-row-end: 14;
    grid-column-start: 5;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment9 {
    grid-row-start: 20;
    grid-row-end: 28;
    grid-column-start: 18;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment10 {
    grid-row-start: 22;
    grid-row-end: 23;
    grid-column-start: 6;
    grid-column-end: 17;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment11 {
    grid-row-start: 21;
    grid-row-end: 28;
    grid-column-start: 45;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.Ct7PainScore {
    grid-row-start: 21;
    grid-row-end: 28;
    grid-column-start: 18;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment15 {
    grid-row-start: 23;
    grid-row-end: 25;
    grid-column-start: 6;
    grid-column-end: 12;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment16 {
    grid-row-start: 23;
    grid-row-end: 25;
    grid-column-start: 39;
    grid-column-end: 43;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment17 {
    grid-row-start: 29;
    grid-row-end: 36;
    grid-column-start: 18;
    grid-column-end: 64;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment18 {
    grid-row-start: 30;
    grid-row-end: 31;
    grid-column-start: 6;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment19 {
    grid-row-start: 32;
    grid-row-end: 35;
    grid-column-start: 65;
    grid-column-end: 67;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment20 {
    grid-row-start: 32;
    grid-row-end: 34;
    grid-column-start: 68;
    grid-column-end: 70;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment21 {
    grid-row-start: 37;
    grid-row-end: 40;
    grid-column-start: 6;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment22 {
    grid-row-start: 37;
    grid-row-end: 42;
    grid-column-start: 21;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment23 {
    grid-row-start: 37;
    grid-row-end: 41;
    grid-column-start: 26;
    grid-column-end: 49;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment24 {
    grid-row-start: 43;
    grid-row-end: 47;
    grid-column-start: 30;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment25 {
    grid-row-start: 44;
    grid-row-end: 47;
    grid-column-start: 21;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment26 {
    grid-row-start: 44;
    grid-row-end: 46;
    grid-column-start: 27;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment27 {
    grid-row-start: 49;
    grid-row-end: 52;
    grid-column-start: 20;
    grid-column-end: 58;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment28 {
    grid-row-start: 50;
    grid-row-end: 51;
    grid-column-start: 4;
    grid-column-end: 10;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment29 {
    grid-row-start: 54;
    grid-row-end: 56;
    grid-column-start: 21;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment30 {
    grid-row-start: 54;
    grid-row-end: 55;
    grid-column-start: 27;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment31 {
    grid-row-start: 58;
    grid-row-end: 60;
    grid-column-start: 21;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment32 {
    grid-row-start: 58;
    grid-row-end: 59;
    grid-column-start: 27;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment33 {
    grid-row-start: 61;
    grid-row-end: 69;
    grid-column-start: 3;
    grid-column-end: 52;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment34 {
    grid-row-start: 63;
    grid-row-end: 68;
    grid-column-start: 15;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment36 {
    grid-row-start: 63;
    grid-row-end: 68;
    grid-column-start: 36;
    grid-column-end: 41;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment38 {
    grid-row-start: 63;
    grid-row-end: 68;
    grid-column-start: 47;
    grid-column-end: 51;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment40 {
    grid-row-start: 65;
    grid-row-end: 66;
    grid-column-start: 6;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment41 {
    grid-row-start: 65;
    grid-row-end: 66;
    grid-column-start: 27;
    grid-column-end: 32;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment42 {
    grid-row-start: 65;
    grid-row-end: 66;
    grid-column-start: 32;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment43 {
    grid-row-start: 65;
    grid-row-end: 66;
    grid-column-start: 42;
    grid-column-end: 43;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment44 {
    grid-row-start: 61;
    grid-row-end: 69;
    grid-column-start: 53;
    grid-column-end: 73;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment45 {
    grid-row-start: 62;
    grid-row-end: 68;
    grid-column-start: 57;
    grid-column-end: 60;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment46 {
    grid-row-start: 62;
    grid-row-end: 68;
    grid-column-start: 62;
    grid-column-end: 72;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment47 {
    grid-row-start: 65;
    grid-row-end: 66;
    grid-column-start: 54;
    grid-column-end: 56;
    width: 100%;
    height: 100%;
}
.PtRehabilitationTreatment48 {
    grid-row-start: 64;
    grid-row-end: 66;
    grid-column-start: 60;
    grid-column-end: 62;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 68;
    grid-row-end: 70;
    grid-column-start: 63;
    grid-column-end: 74;
    width: 100%;
    height: 100%;
}
