#tab-card-or-request-history {
  pointer-events: none;

  #enable-pointer-tap {
    a[id^="MenuItem-team"] {
      pointer-events: auto;
    }
  }
}

#tab-card-pre-operation-history {
  pointer-events: none;
  #tab-button{ 
    pointer-events: auto;
  }
}

#tab-card-surgical-safety-checklist-history {
  pointer-events: none;
}

#tab-card-perioperative-nursing-record-history {
  pointer-events: none;
  #tab-button{ 
    pointer-events: auto;
  }
}

#tab-card-post-operation-history {
  pointer-events: none;
}

.block-mouse {
  touch-action: none;
  pointer-events: none;
}