.card-adverse-reaction-note {
  .card-title {
    padding: 20px 10px;
    font-weight: bold;
    font-size: large;
  }
  .flex {
    display: flex;
    padding: 5px 10px;

    &.verify-by-phama {
      padding: 30px;
      align-items: baseline;
      justify-content: space-evenly;
    }

    .field-title {
      width: 15%;
      align-self: center;

      &.text-area {
        align-self: flex-start;
      }

      &.red {
        color: red;
      }
    }

    .grow {
      flex-grow: 1;
    }
    .grow-other{
      flex-grow: 1;
    }
  }
}

.card-adverse-reaction-note-menu {
  display: flex;
  justify-content: flex-start;
  padding-left: 25px;
  padding-right: 25px;

  .card-adverse-reaction-note-menu-title {
    width: 20%;
    align-self: center;
  }
  .card-adverse-reaction-note-menu-button {
  }
 
  
}
.floating-label{
  display: inline-block;
  position:absolute;
  left: 15px;
  top: -13px;
}
.card-adverse-reaction-table{
  background-color: #E9F8FC;
}
.card-adverse-reaction-checkbox{
  width: 15px;
  height: 15px;
}


