
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.44642857142857145% 2.6785714285714284% 1.5625% 1.5625% 1.1160714285714286% 2.0089285714285716% 1.5625% 1.5625% 1.1160714285714286% 2.0089285714285716% 1.3392857142857142% 0.22321428571428573% 0.22321428571428573% 0.44642857142857145% 0.6696428571428571% 0.22321428571428573% 0.8928571428571429% 2.0089285714285716% 1.3392857142857142% 1.7857142857142858% 0.8928571428571429% 2.455357142857143% 0.44642857142857145% 0.8928571428571429% 0.22321428571428573% 0.22321428571428573% 0.44642857142857145% 0.6696428571428571% 0.22321428571428573% 0.8928571428571429% 0.44642857142857145% 2.232142857142857% 1.1160714285714286% 0.44642857142857145% 0.44642857142857145% 1.1160714285714286% 0.22321428571428573% 1.1160714285714286% 6.696428571428571% 3.125% 0.44642857142857145% 0.22321428571428573% 1.3392857142857142% 0.44642857142857145% 0.8928571428571429% 0.6696428571428571% 0.6696428571428571% 0.6696428571428571% 1.5625% 0.8928571428571429% 0.44642857142857145% 2.232142857142857% 1.1160714285714286% 1.5625% 1.3392857142857142% 2.9017857142857144% 0.6696428571428571% 1.7857142857142858% 0.8928571428571429% 2.0089285714285716% 0.8928571428571429% 1.3392857142857142% 1.1160714285714286% 4.017857142857143% 2.232142857142857% 0.22321428571428573% 0.8928571428571429% 0.22321428571428573% 1.3392857142857142% 0.22321428571428573% 0.22321428571428573% 0.8928571428571429% 2.455357142857143% 2.9017857142857144% 1.5625% 1.7857142857142858% 1.3392857142857142% 2.0089285714285716% 2.455357142857143%;
    grid-template-columns: 0.5213764337851929% 1.1470281543274243% 0.7299270072992701% 0.10427528675703858% 0.10427528675703858% 1.981230448383733% 1.4598540145985401% 1.0427528675703859% 0.10427528675703858% 0.4171011470281543% 0.9384775808133472% 0.31282586027111575% 1.4598540145985401% 0.10427528675703858% 6.882168925964546% 0.5213764337851929% 0.31282586027111575% 0.20855057351407716% 0.10427528675703858% 0.4171011470281543% 0.10427528675703858% 0.20855057351407716% 0.7299270072992701% 0.7299270072992701% 0.9384775808133472% 0.31282586027111575% 0.8342022940563086% 0.4171011470281543% 3.545359749739312% 1.4598540145985401% 0.31282586027111575% 1.5641293013555788% 0.6256517205422315% 0.10427528675703858% 0.4171011470281543% 0.7299270072992701% 0.6256517205422315% 3.962460896767466% 1.0427528675703859% 0.20855057351407716% 0.31282586027111575% 1.251303441084463% 0.8342022940563086% 0.5213764337851929% 0.7299270072992701% 0.10427528675703858% 1.3555787278415015% 4.2752867570385815% 1.0427528675703859% 0.5213764337851929% 1.251303441084463% 0.4171011470281543% 0.8342022940563086% 0.7299270072992701% 1.251303441084463% 0.5213764337851929% 6.465067778936392% 4.588112617309697% 1.772679874869656% 4.37956204379562% 0.8342022940563086% 19.499478623566215% 1.1470281543274243% 0.8342022940563086% 1.3555787278415015% 0.10427528675703858% 0.7299270072992701% 1.0427528675703859% 0.4171011470281543% 0.4171011470281543% 0.6256517205422315% 0.7299270072992701% 1.0427528675703859% 0.31282586027111575%;
}

.PoRehabilitationTreatmentAnkleFootOrthotic1 {
    grid-row-start: 2;
    grid-row-end: 75;
    grid-column-start: 2;
    grid-column-end: 74;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic2 {
    grid-row-start: 3;
    grid-row-end: 6;
    grid-column-start: 17;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic3 {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 4;
    grid-column-end: 8;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic4 {
    grid-row-start: 7;
    grid-row-end: 10;
    grid-column-start: 17;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic5 {
    grid-row-start: 8;
    grid-row-end: 9;
    grid-column-start: 5;
    grid-column-end: 16;
    width: 100%;
    height: 100%;
}
.CtTreatmentAnklefootOrthosis {
    grid-row-start: 11;
    grid-row-end: 18;
    grid-column-start: 17;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic9 {
    grid-row-start: 11;
    grid-row-end: 32;
    grid-column-start: 38;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic10 {
    grid-row-start: 13;
    grid-row-end: 16;
    grid-column-start: 5;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic11 {
    grid-row-start: 19;
    grid-row-end: 22;
    grid-column-start: 17;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic12 {
    grid-row-start: 20;
    grid-row-end: 21;
    grid-column-start: 6;
    grid-column-end: 16;
    width: 100%;
    height: 100%;
}
.Ct3PainScore {
    grid-row-start: 24;
    grid-row-end: 32;
    grid-column-start: 17;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic16 {
    grid-row-start: 23;
    grid-row-end: 31;
    grid-column-start: 47;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic17 {
    grid-row-start: 26;
    grid-row-end: 29;
    grid-column-start: 6;
    grid-column-end: 12;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic18 {
    grid-row-start: 25;
    grid-row-end: 29;
    grid-column-start: 41;
    grid-column-end: 44;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic19 {
    grid-row-start: 33;
    grid-row-end: 40;
    grid-column-start: 17;
    grid-column-end: 64;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic20 {
    grid-row-start: 35;
    grid-row-end: 37;
    grid-column-start: 6;
    grid-column-end: 7;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic21 {
    grid-row-start: 34;
    grid-row-end: 39;
    grid-column-start: 65;
    grid-column-end: 66;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic22 {
    grid-row-start: 34;
    grid-row-end: 37;
    grid-column-start: 67;
    grid-column-end: 70;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic23 {
    grid-row-start: 41;
    grid-row-end: 46;
    grid-column-start: 20;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic24 {
    grid-row-start: 42;
    grid-row-end: 44;
    grid-column-start: 6;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic25 {
    grid-row-start: 41;
    grid-row-end: 45;
    grid-column-start: 25;
    grid-column-end: 50;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic26 {
    grid-row-start: 47;
    grid-row-end: 52;
    grid-column-start: 29;
    grid-column-end: 52;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic27 {
    grid-row-start: 48;
    grid-row-end: 51;
    grid-column-start: 20;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic28 {
    grid-row-start: 48;
    grid-row-end: 50;
    grid-column-start: 25;
    grid-column-end: 27;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic29 {
    grid-row-start: 53;
    grid-row-end: 56;
    grid-column-start: 18;
    grid-column-end: 58;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic30 {
    grid-row-start: 54;
    grid-row-end: 55;
    grid-column-start: 4;
    grid-column-end: 9;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic31 {
    grid-row-start: 57;
    grid-row-end: 60;
    grid-column-start: 20;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic32 {
    grid-row-start: 57;
    grid-row-end: 59;
    grid-column-start: 25;
    grid-column-end: 59;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic33 {
    grid-row-start: 61;
    grid-row-end: 64;
    grid-column-start: 20;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic34 {
    grid-row-start: 61;
    grid-row-end: 63;
    grid-column-start: 25;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic53 {
    grid-row-start: 65;
    grid-row-end: 80;
    grid-column-start: 3;
    grid-column-end: 73;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 76;
    grid-row-end: 79;
    grid-column-start: 63;
    grid-column-end: 74;
    width: 100%;
    height: 100%;
}
.PoRehabilitationTreatmentAnkleFootOrthotic52 {
    grid-row-start: 77;
    grid-row-end: 78;
    grid-column-start: 66;
    grid-column-end: 69;
    width: 100%;
    height: 100%;
}
