
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 2.0833333333333335% 1.7628205128205128% 3.2051282051282053% 0.16025641025641027% 2.4038461538461537% 1.6025641025641026% 0.16025641025641027% 0.16025641025641027% 0.8012820512820513% 0.4807692307692308% 0.16025641025641027% 0.6410256410256411% 0.6410256410256411% 0.32051282051282054% 1.6025641025641026% 0.8012820512820513% 0.16025641025641027% 1.4423076923076923% 1.6025641025641026% 11.698717948717949% 1.9230769230769231% 1.6025641025641026% 0.16025641025641027% 0.16025641025641027% 0.8012820512820513% 0.4807692307692308% 0.4807692307692308% 0.9615384615384616% 2.4038461538461537% 12.01923076923077% 1.7628205128205128% 1.6025641025641026% 0.16025641025641027% 0.16025641025641027% 0.8012820512820513% 0.4807692307692308% 0.16025641025641027% 1.2820512820512822% 2.4038461538461537% 11.698717948717949% 1.7628205128205128% 1.1217948717948718% 0.4807692307692308% 0.4807692307692308% 0.6410256410256411% 0.32051282051282054% 0.16025641025641027% 1.6025641025641026% 1.9230769230769231% 1.6025641025641026% 0.32051282051282054% 1.1217948717948718% 11.698717948717949% 3.3653846153846154%;
    grid-template-columns: 1.0695187165775402% 0.06684491978609626% 5.4144385026737964% 0.20053475935828877% 1.0695187165775402% 3.943850267379679% 0.06684491978609626% 0.06684491978609626% 0.06684491978609626% 2.2058823529411766% 0.3342245989304813% 1.0026737967914439% 0.26737967914438504% 0.06684491978609626% 0.9358288770053476% 0.26737967914438504% 0.3342245989304813% 2.272727272727273% 1.804812834224599% 0.06684491978609626% 0.40106951871657753% 0.8021390374331551% 1.2700534759358288% 1.4705882352941178% 7.820855614973262% 0.06684491978609626% 0.4679144385026738% 0.06684491978609626% 0.8021390374331551% 20.387700534759357% 0.8021390374331551% 0.06684491978609626% 3.342245989304813% 2.7406417112299466% 37.032085561497325% 0.06684491978609626% 0.06684491978609626% 0.8021390374331551%;
}

.OtPhysicalPediatric21 {
    grid-row-start: 1;
    grid-row-end: 200;
    grid-column-start: 1;
    grid-column-end: 200;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric21 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 5;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric22 {
    grid-row-start: 4;
    grid-row-end: 4;
    grid-column-start: 3;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
.CtPersonalAndSocial {
    grid-row-start: 6;
    grid-row-end: 14;
    grid-column-start: 23;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric26 {
    grid-row-start: 7;
    grid-row-end: 13;
    grid-column-start: 3;
    grid-column-end: 6;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric27 {
    grid-row-start: 7;
    grid-row-end: 13;
    grid-column-start: 8;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric211 {
    grid-row-start: 16;
    grid-row-end: 19;
    grid-column-start: 8;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric212 {
    grid-row-start: 16;
    grid-row-end: 19;
    grid-column-start: 34;
    grid-column-end: 40;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric213 {
    grid-row-start: 20;
    grid-row-end: 21;
    grid-column-start: 8;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric214 {
    grid-row-start: 20;
    grid-row-end: 21;
    grid-column-start: 34;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
.CtFineMotor {
    grid-row-start: 22;
    grid-row-end: 29;
    grid-column-start: 23;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric216 {
    grid-row-start: 23;
    grid-row-end: 29;
    grid-column-start: 8;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric219 {
    grid-row-start: 30;
    grid-row-end: 31;
    grid-column-start: 7;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.CtGrossMotor {
    grid-row-start: 32;
    grid-row-end: 39;
    grid-column-start: 23;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric223 {
    grid-row-start: 34;
    grid-row-end: 37;
    grid-column-start: 9;
    grid-column-end: 20;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric225 {
    grid-row-start: 40;
    grid-row-end: 41;
    grid-column-start: 8;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
.CtLanguage {
    grid-row-start: 42;
    grid-row-end: 49;
    grid-column-start: 23;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric229 {
    grid-row-start: 43;
    grid-row-end: 47;
    grid-column-start: 10;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric230 {
    grid-row-start: 50;
    grid-row-end: 52;
    grid-column-start: 10;
    grid-column-end: 25;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric231 {
    grid-row-start: 50;
    grid-row-end: 51;
    grid-column-start: 34;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric232 {
    grid-row-start: 53;
    grid-row-end: 54;
    grid-column-start: 8;
    grid-column-end: 31;
    width: 100%;
    height: 100%;
}
.OtPhysicalPediatric233 {
    grid-row-start: 53;
    grid-row-end: 54;
    grid-column-start: 34;
    grid-column-end: 36;
    width: 100%;
    height: 100%;
}
