
.container {
    height: 100%;
    display: grid;
    grid-template-rows: 0.0% 0.45454545454545453% 4.318181818181818% 0.6818181818181818% 1.3636363636363635% 1.1363636363636365% 1.3636363636363635% 0.6818181818181818% 1.8181818181818181% 0.9090909090909091% 1.5909090909090908% 1.1363636363636365% 2.0454545454545454% 1.3636363636363635% 1.5909090909090908% 0.9090909090909091% 2.0454545454545454% 0.45454545454545453% 0.9090909090909091% 0.22727272727272727% 0.22727272727272727% 0.22727272727272727% 0.6818181818181818% 0.22727272727272727% 0.9090909090909091% 0.45454545454545453% 2.272727272727273% 0.9090909090909091% 0.45454545454545453% 0.22727272727272727% 1.1363636363636365% 0.22727272727272727% 0.9090909090909091% 6.590909090909091% 3.1818181818181817% 0.45454545454545453% 1.3636363636363635% 0.45454545454545453% 0.6818181818181818% 0.9090909090909091% 0.6818181818181818% 0.6818181818181818% 1.3636363636363635% 0.9090909090909091% 0.22727272727272727% 2.272727272727273% 1.1363636363636365% 1.3636363636363635% 1.3636363636363635% 2.9545454545454546% 0.6818181818181818% 1.5909090909090908% 0.6818181818181818% 2.272727272727273% 0.6818181818181818% 1.1363636363636365% 1.1363636363636365% 6.818181818181818% 2.272727272727273% 0.22727272727272727% 0.6818181818181818% 0.22727272727272727% 0.22727272727272727% 1.1363636363636365% 0.22727272727272727% 0.22727272727272727% 0.9090909090909091% 2.272727272727273% 5.2272727272727275% 0.45454545454545453% 1.5909090909090908% 1.3636363636363635% 1.8181818181818181% 5.681818181818182% 0.0%;
    grid-template-columns: 0.0% 0.10964912280701754% 0.5482456140350878% 0.21929824561403508% 1.0964912280701755% 0.5482456140350878% 0.10964912280701754% 0.10964912280701754% 0.10964912280701754% 1.8640350877192982% 2.5219298245614037% 0.10964912280701754% 0.5482456140350878% 0.9868421052631579% 0.10964912280701754% 1.4254385964912282% 0.10964912280701754% 2.0833333333333335% 4.934210526315789% 0.10964912280701754% 0.32894736842105265% 0.21929824561403508% 0.32894736842105265% 0.10964912280701754% 0.43859649122807015% 0.21929824561403508% 0.6578947368421053% 0.7675438596491229% 1.0964912280701755% 0.10964912280701754% 1.206140350877193% 3.837719298245614% 1.4254385964912282% 1.8640350877192982% 0.43859649122807015% 0.21929824561403508% 0.21929824561403508% 0.7675438596491229% 0.8771929824561403% 0.5482456140350878% 3.289473684210526% 0.9868421052631579% 0.10964912280701754% 0.43859649122807015% 1.3157894736842106% 0.5482456140350878% 0.7675438596491229% 0.6578947368421053% 1.3157894736842106% 4.495614035087719% 0.21929824561403508% 0.5482456140350878% 0.21929824561403508% 0.5482456140350878% 0.9868421052631579% 0.21929824561403508% 0.43859649122807015% 0.7675438596491229% 0.5482456140350878% 1.4254385964912282% 0.5482456140350878% 6.25% 4.714912280701754% 1.7543859649122806% 4.495614035087719% 0.7675438596491229% 19.18859649122807% 1.0964912280701755% 0.8771929824561403% 1.4254385964912282% 0.7675438596491229% 0.9868421052631579% 0.43859649122807015% 0.43859649122807015% 0.7675438596491229% 0.6578947368421053% 0.9868421052631579% 0.43859649122807015% 0.21929824561403508% 0.0%;
}

.OtRehabilitationTreatment2 {
    grid-row-start: 1;
    grid-row-end: 70;
    grid-column-start: 4;
    grid-column-end: 78;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment3 {
    grid-row-start: 4;
    grid-row-end: 6;
    grid-column-start: 9;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment4 {
    grid-row-start: 5;
    grid-row-end: 8;
    grid-column-start: 22;
    grid-column-end: 75;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment6 {
    grid-row-start: 10;
    grid-row-end: 13;
    grid-column-start: 22;
    grid-column-end: 75;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment7 {
    grid-row-start: 11;
    grid-row-end: 12;
    grid-column-start: 8;
    grid-column-end: 19;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment8 {
    grid-row-start: 14;
    grid-row-end: 27;
    grid-column-start: 22;
    grid-column-end: 75;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment9 {
    grid-row-start: 15;
    grid-row-end: 16;
    grid-column-start: 8;
    grid-column-end: 14;
    width: 100%;
    height: 100%;
}
.Ct6PainScore {
    grid-row-start: 19;
    grid-row-end: 27;
    grid-column-start: 22;
    grid-column-end: 39;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment13 {
    grid-row-start: 18;
    grid-row-end: 26;
    grid-column-start: 49;
    grid-column-end: 75;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment14 {
    grid-row-start: 21;
    grid-row-end: 24;
    grid-column-start: 9;
    grid-column-end: 15;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment15 {
    grid-row-start: 20;
    grid-row-end: 24;
    grid-column-start: 44;
    grid-column-end: 47;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment16 {
    grid-row-start: 28;
    grid-row-end: 35;
    grid-column-start: 22;
    grid-column-end: 69;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment17 {
    grid-row-start: 30;
    grid-row-end: 32;
    grid-column-start: 9;
    grid-column-end: 11;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment18 {
    grid-row-start: 29;
    grid-row-end: 34;
    grid-column-start: 70;
    grid-column-end: 71;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment19 {
    grid-row-start: 29;
    grid-row-end: 32;
    grid-column-start: 72;
    grid-column-end: 74;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment20 {
    grid-row-start: 36;
    grid-row-end: 40;
    grid-column-start: 25;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment21 {
    grid-row-start: 36;
    grid-row-end: 38;
    grid-column-start: 9;
    grid-column-end: 17;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment22 {
    grid-row-start: 36;
    grid-row-end: 39;
    grid-column-start: 29;
    grid-column-end: 55;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment23 {
    grid-row-start: 41;
    grid-row-end: 45;
    grid-column-start: 32;
    grid-column-end: 56;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment24 {
    grid-row-start: 42;
    grid-row-end: 45;
    grid-column-start: 25;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment25 {
    grid-row-start: 42;
    grid-row-end: 44;
    grid-column-start: 29;
    grid-column-end: 30;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment26 {
    grid-row-start: 47;
    grid-row-end: 50;
    grid-column-start: 23;
    grid-column-end: 63;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment27 {
    grid-row-start: 48;
    grid-row-end: 49;
    grid-column-start: 7;
    grid-column-end: 12;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment28 {
    grid-row-start: 51;
    grid-row-end: 54;
    grid-column-start: 25;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment29 {
    grid-row-start: 51;
    grid-row-end: 53;
    grid-column-start: 29;
    grid-column-end: 65;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment31 {
    grid-row-start: 55;
    grid-row-end: 58;
    grid-column-start: 25;
    grid-column-end: 28;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment32 {
    grid-row-start: 55;
    grid-row-end: 57;
    grid-column-start: 29;
    grid-column-end: 37;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment33 {
    grid-row-start: 59;
    grid-row-end: 69;
    grid-column-start: 6;
    grid-column-end: 57;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment34 {
    grid-row-start: 61;
    grid-row-end: 68;
    grid-column-start: 17;
    grid-column-end: 27;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment35 {
    grid-row-start: 64;
    grid-row-end: 65;
    grid-column-start: 21;
    grid-column-end: 24;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment36 {
    grid-row-start: 61;
    grid-row-end: 68;
    grid-column-start: 37;
    grid-column-end: 45;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment38 {
    grid-row-start: 61;
    grid-row-end: 68;
    grid-column-start: 49;
    grid-column-end: 55;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment41 {
    grid-row-start: 63;
    grid-row-end: 66;
    grid-column-start: 10;
    grid-column-end: 17;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment42 {
    grid-row-start: 64;
    grid-row-end: 65;
    grid-column-start: 29;
    grid-column-end: 34;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment43 {
    grid-row-start: 64;
    grid-row-end: 65;
    grid-column-start: 34;
    grid-column-end: 35;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment44 {
    grid-row-start: 63;
    grid-row-end: 65;
    grid-column-start: 46;
    grid-column-end: 48;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment45 {
    grid-row-start: 59;
    grid-row-end: 69;
    grid-column-start: 58;
    grid-column-end: 77;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment46 {
    grid-row-start: 60;
    grid-row-end: 68;
    grid-column-start: 62;
    grid-column-end: 64;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment47 {
    grid-row-start: 60;
    grid-row-end: 68;
    grid-column-start: 67;
    grid-column-end: 76;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment48 {
    grid-row-start: 62;
    grid-row-end: 66;
    grid-column-start: 65;
    grid-column-end: 67;
    width: 100%;
    height: 100%;
}
.OtRehabilitationTreatment49 {
    grid-row-start: 64;
    grid-row-end: 65;
    grid-column-start: 59;
    grid-column-end: 61;
    width: 100%;
    height: 100%;
}
.SaveRequest {
    grid-row-start: 71;
    grid-row-end: 74;
    grid-column-start: 68;
    grid-column-end: 78;
    width: 100%;
    height: 100%;
}
