/* SummaryPrice.css react-lib */

.summary__total-rows input{
  text-align: right !important;
}

.summary__total-price input{
  text-align: right !important;
}

.summary__total-paid input{
  text-align: right !important;
}

.summary__total-other-pay input{
  text-align: right !important;
}

.summary__total-sent-claim input{
  text-align: right !important;
}

.summary__total-price-default input{
  text-align: right !important;
}
