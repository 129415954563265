.chart-summary {
  padding: 3px;
  background-color: white;
  display: grid;
  grid-template-columns: 28% 36% 36%;
  grid-template-rows: auto;
  column-gap: 3px;
  .patient-care {
    background-color: #ec7a7a;
  }

  .cs-left {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
    display:flex;
    flex-direction: column;
  }

  .cs-center {
    width: 100%;
    grid-column-start: 2;
    grid-column-end: 3;
    display:flex;
    flex-direction: column;
  }

  .cs-right {
    width: 100%;
    grid-column-start: 3;
    grid-column-end: 4;
    display:flex;
    flex-direction: column;
  }

}

.chart-patient{
  width: 100%;
  height: 50px;
  background-color: #F2F2F2;
  padding: 10px;
  display: flex;
  align-items: center;

  div {
    padding-right: 10px;
  }

  .font-bold{
    font-weight: bold;
  }
}

